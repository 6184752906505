import React from "react";
import { Result } from "antd";

import "./shared.css";

export default ({ status = 500, error }) => {
  return (
    <div className="fullPage">
      <Result status={status} title={error} />
    </div>
  );
};
