import React from "react";
import { Button, Switch } from "antd";

import { multiselectQuestion } from "../../../utils/constants";

const MarkAnswerButton = ({
  questionType,
  questionId,
  correctAnswer,
  markCorrectOption,
  markIncorrectOption,
  optionId,
}) => {
  let showMarkCorrect = true;
  if (questionType === multiselectQuestion.type) {
    if (
      (correctAnswer || {}).value &&
      correctAnswer.value.filter((item) => item === optionId).length
    ) {
      showMarkCorrect = false;
    }
  } else if ((correctAnswer || {}).value === optionId) {
    showMarkCorrect = false;
  }
  const onClick = () => {
    if (showMarkCorrect) {
      markCorrectOption({ questionType, optionId, questionId });
    } else {
      markIncorrectOption({ questionType, optionId, questionId });
    }
  };
  return (
    <>
      <Button onClick={onClick} size="small">
        Mark Correct &nbsp;
        <Switch size="small" checked={!showMarkCorrect} />
      </Button>
    </>
  );
};

export default MarkAnswerButton;
