import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  Row,
  Col,
  PageHeader,
  Card,
  Skeleton,
  Button,
  message,
  Popconfirm,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { useLocation, useParams, Link } from "react-router-dom";
import { DateTime } from "luxon";

import Error from "../shared/Error";
import { get, put, del } from "../../utils/networkUtils";
import { assessment as assessmentApi } from "../../utils/apiDict";
import AssessmentForm from "../shared/AssessmentForm/AssessmentForm";
import AssessmentPreview from "./AssessmentPreview";
import { assessmentListUrl } from "../../utils/constants";
import PublishButton from "./PublishButton";

const deleteAssessment = async ({ assessmentId, setDeleteLoading }) => {
  setDeleteLoading(true);
  const response = await del({
    url: assessmentApi.replace("/:uid", "").replace(":aid", assessmentId),
  });
  if (!response.ok || !(response.data || {}).ok) {
    setDeleteLoading(false);
    return message.error("Unable to delete assessment. Please try later");
  }
  message.success("Assessment Deleted");
  // deletion successful, redirect to assessment list page
  return setTimeout(() => {
    window.location = `${process.env.REACT_APP_UOLO_WEB}${assessmentListUrl}`;
  }, 3000);
};

const editAssessment = async ({
  formData,
  assessmentId,
  setError,
  setEditLoading,
  setPreviewRedirect,
}) => {
  const dateFormat = "yyyy-MM-dd HH:mm:ss";
  const startDateTime = DateTime.fromISO(formData.startDate);
  const putData = {
    title: formData.title,
    groupIds: formData.groupIds,
    notificationTime: startDateTime
      .minus({ minutes: formData.notificationTime })
      .toFormat(dateFormat),
    startTime: startDateTime.toFormat(dateFormat),
    endTime: startDateTime
      .plus({ minutes: formData.duration })
      .toFormat(dateFormat),
  };
  if (formData.description) {
    putData.description = formData.description;
  }
  setError("");
  setEditLoading(true);
  const putResponse = await put({
    url: `${assessmentApi.replace(":aid/:uid", "")}${assessmentId}`,
    body: putData,
    config: { withCredentials: true },
  });
  setEditLoading(false);
  if (!putResponse.ok) {
    return setError(
      putResponse.err || "Something went wrong. Please try later"
    );
  }
  if (!putResponse.data.ok) {
    return setError(
      putResponse.data.err || "Something went wrong. Please try later"
    );
  }
  return setPreviewRedirect(true);
};

const getAssessmentData = async ({
  assessmentId,
  setError,
  setLoading,
  setAssessmentData,
}) => {
  try {
    setLoading(true);
    const assessmentResponse = await get({
      url: `${assessmentApi.replace(":aid", assessmentId).replace(":uid", "")}`,
      config: { withCredentials: true },
    });
    if (assessmentResponse.ok && assessmentResponse.data.ok) {
      setAssessmentData((assessmentResponse.data.data || {}).data);
    } else {
      throw assessmentResponse.err || "some error occured. please try later";
    }
    setLoading(false);
  } catch (e) {
    setError(e.message || e);
  }
};

const AssessmentPreviewAndUpdate = () => {
  const location = useLocation();
  const { aid: assessmentId } = useParams();
  const previewMode = location.hash === "#preview";
  const [loading, setLoading] = useState(true);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [previewRedirect, setPreviewRedirect] = useState(false);
  const [error, setError] = useState("");
  const [assessmentData, setAssessmentData] = useState({});
  const onSubmit = async (values) => {
    await editAssessment({
      formData: values,
      assessmentId,
      setAssessmentData,
      setError,
      setEditLoading,
      setPreviewRedirect,
    });
    getAssessmentData({
      assessmentId,
      setLoading,
      setError,
      setAssessmentData,
    });
  };
  useEffect(() => {
    getAssessmentData({
      assessmentId,
      setLoading,
      setError,
      setAssessmentData,
    });
  }, [assessmentId]);
  let editAllowed = false;
  if ((assessmentData || {}).canEdit) {
    editAllowed = true;
  } else if (!previewMode && !loading && !error) {
    // condition when edit is not allowed
    window.location.hash = "#preview";
    return <></>;
  }
  let deleteAllowed = false;
  if ((assessmentData || {}).canDelete) {
    deleteAllowed = true;
  }

  let publishAllowed = false;
  if ((assessmentData || {}).canPublish) {
    publishAllowed = true;
  }
  if (previewRedirect) {
    setPreviewRedirect(false);
    window.location.hash = "#preview";
    return <></>;
  }
  return (
    <>
      {error && <Error error={error} />}
      {!error && (
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 16, offset: 4 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 8, offset: 8 }}
          >
            {loading && <Skeleton />}
            {!loading && assessmentData && (
              <>
                <PageHeader
                  title={previewMode ? "Assessment" : "Edit Assessment"}
                  extra={
                    previewMode
                      ? [
                          editAllowed && (
                            <Button
                              key="edit-button"
                              icon={<EditOutlined />}
                              type="primary"
                              href="#edit"
                              disabled={deleteLoading}
                            >
                              Edit
                            </Button>
                          ),
                          <Link
                            key="preview-question"
                            to={`/quiz/${assessmentData.quizId}/preview`}
                          >
                            <Button
                              type="primary"
                              icon={<PlayCircleOutlined />}
                              disabled={deleteLoading}
                            >
                              Preview Questions
                            </Button>
                          </Link>,
                          deleteAllowed && (
                            <Popconfirm
                              key="delete-button"
                              title="Are you sure you want to delete?"
                              okText="Delete"
                              okType="danger"
                              onConfirm={() =>
                                deleteAssessment({
                                  assessmentId,
                                  setDeleteLoading,
                                })
                              }
                              cancelText="Cancel"
                            >
                              <Button
                                icon={<DeleteOutlined />}
                                loading={deleteLoading}
                                type="danger"
                              >
                                Delete
                              </Button>
                            </Popconfirm>
                          ),
                          publishAllowed && (
                            <PublishButton
                              key="publish-button"
                              lastestPublishTime={
                                (assessmentData || {}).latestPublishTime
                              }
                              assessmentId={assessmentId}
                              setPublishTime={(publishTime) => {
                                setAssessmentData({
                                  ...assessmentData,
                                  latestPublishTime: publishTime,
                                });
                              }}
                            />
                          ),
                        ]
                      : [
                          <Button type="primary" href="#preview">
                            Preview
                          </Button>,
                        ]
                  }
                />
                <Card>
                  {previewMode && (
                    <AssessmentPreview formData={assessmentData} />
                  )}
                  {!previewMode && (
                    <AssessmentForm
                      formData={assessmentData}
                      onSubmitForm={onSubmit}
                      loading={editLoading}
                    />
                  )}
                </Card>
              </>
            )}
            {!loading && !assessmentData && (
              <Error error="Assessment not found" />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default AssessmentPreviewAndUpdate;
