import React, { useState } from "react";
import { Image, Button, Tooltip } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import { serveImage as imageServeUrl } from "../../utils/apiDict";
import { imageSize } from "../../utils/constants";

const ReloadableImage = ({ value, className }) => {
  const [random, setRandom] = useState();
  return (
    <>
      <br />
      <Image
        className={className}
        src={`${process.env.REACT_APP_API_SERVER}${imageServeUrl}${value}/${imageSize}?${random}`}
        key={value}
        fallback={`${process.env.PUBLIC_URL}/placeholder.png`}
      />
      <Tooltip title="Reload">
        <Button
          type="dashed"
          shape="circle"
          icon={<ReloadOutlined />}
          style={{ marginLeft: 10 }}
          onClick={() => {
            setRandom(Date.now());
          }}
        />
      </Tooltip>
    </>
  );
};

export default ReloadableImage;
