import { useState } from "react";
import { put } from "../utils/networkUtils";
import { quiz as quizAPI } from "../utils/apiDict";

const useQuizEdit = ({ quizId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const editQuiz = async ({ quizData }) => {
    setLoading(true);
    setError("");
    setSuccess(false);
    const resp = await put({
      url: `${quizAPI}/${quizId}`,
      body: {
        ...quizData,
      },
      config: { withCredentials: true },
    });
    setLoading(false);
    if (resp.ok && resp.data.ok) {
      return setSuccess(true);
    }
    return setError((resp.originalError || {}).message || resp.data.err);
  };

  return {
    editQuiz,
    loading,
    error,
    success,
  };
};

export default useQuizEdit;
