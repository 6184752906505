import { useState } from "react";

import { post } from "../utils/networkUtils";
import { publishStudentsAssessmentReport } from "../utils/apiDict";

const usePublishStudentsAssessmentReport = ({ assessmentId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const publishReport = async () => {
    setLoading(true);
    const res = await post({
      url: publishStudentsAssessmentReport.replace(":aId", assessmentId),
    });
    if (!(res.ok && (res.data || {}).ok)) {
      setError(
        (res.originalError || {}).message || (res.data || {}).err || "Something went wrong. Please try later"
      );
    }
    setLoading(false);
    return res;
  };
  return {
    publishReport,
    loading,
    error,
  };
};

export default usePublishStudentsAssessmentReport;
