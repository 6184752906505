/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Row } from "antd";
import React from "react";

const WelcomeScreen = ({ startQuiz, setQuizType }) => {
  return (
    <Col style={{ backgroundColor: "#fff7e5", minHeight: "100vh" }}>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 16, offset: 4 }}
        lg={{ span: 12, offset: 6 }}
        xl={{ span: 8, offset: 8 }}
      >
        <Row style={{ justifyContent: "center" }}>
          <img
            src="/uoloLogo.svg"
            style={{ marginTop: "76px", height: "31px" }}
            alt="UOLO"
          />
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px 0px 60px",
          }}
        >
          <Row justify="center" gutter={4}>
            <span style={{ fontSize: "40px", fontWeight: "bold" }}>
              QUIZ TIME
            </span>
          </Row>
          <Row justify="center">
            <img
              src="/playquiz/quiz_hero_img.svg"
              alt="QUIZ"
              style={{ height: 70 }}
            />
          </Row>
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-around",
              margin: "20px 0px 20px 0px",
            }}
          >
            <div
              style={{
                height: "50px",
                borderRadius: "20px",
                boxShadow: "10px 10px 10px 0px",
                backgroundColor: "#561FE7",
                cursor: "pointer",
                margin: "40px 0px 20px 0px",
              }}
              onClick={() => {
                setQuizType("speaking");
                startQuiz();
              }}
            >
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 8,
                  color: "white",
                  fontFamily: "Open Sans",
                  fontSize: 22,
                  fontWeight: 500,
                  fontStyle: "normal",
                }}
              >
                Speaking
              </p>
            </div>

            <div
              style={{
                height: "50px",
                borderRadius: "20px",
                boxShadow: "10px 10px 10px 0px",
                backgroundColor: "#561FE7",
                cursor: "pointer",
                margin: "40px 0px 20px 0px",
              }}
              onClick={() => {
                setQuizType("listening");
                startQuiz();
              }}
            >
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 8,
                  color: "white",
                  fontFamily: "Open Sans",
                  fontSize: 22,
                  fontWeight: 500,
                  fontStyle: "normal",
                }}
              >
                Listening
              </p>
            </div>
            <div
              style={{
                height: "50px",
                borderRadius: "20px",
                boxShadow: "10px 10px 10px 0px",
                backgroundColor: "#561FE7",
                cursor: "pointer",
                margin: "40px 0px 20px 0px",
              }}
              onClick={() => {
                setQuizType("grammar");
                startQuiz();
              }}
            >
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 8,
                  color: "white",
                  fontFamily: "Open Sans",
                  fontSize: 22,
                  fontWeight: 500,
                  fontStyle: "normal",
                }}
              >
                Grammar
              </p>
            </div>
          </div>
        </div>
      </Col>
    </Col>
  );
};

export default WelcomeScreen;
