/* eslint react/prop-types: 0 */
// @todo anupam - add prop types
import React from "react";
import { Typography } from "antd";
import { DateTime } from "luxon";

const { Title, Text } = Typography;
const TitleLevel4 = ({ children }) => (
  <Title style={{ fontSize: "14px" }} level={4}>
    {children}
  </Title>
);
const AssessmentPreview = ({
  formData: { title, subject, description, startTime, endTime },
}) => {
  return (
    <>
      <TitleLevel4>Title</TitleLevel4>
      <Text>{title}</Text>
      <TitleLevel4>Subject</TitleLevel4>
      <Text>{subject}</Text>
      {description && (
        <>
          <TitleLevel4>Description</TitleLevel4>
          <Text>
            {(description || "").split("\n").map((line) => (
              <React.Fragment key={line}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Text>
        </>
      )}
      <TitleLevel4>Start time</TitleLevel4>
      <Text>{DateTime.fromISO(startTime).toFormat("dd-MMM-yyyy h:mm a")}</Text>
      <TitleLevel4>End time</TitleLevel4>
      <Text>{DateTime.fromISO(endTime).toFormat("dd-MMM-yyyy h:mm a")}</Text>
    </>
  );
};

export default AssessmentPreview;
