export const multiselectQuestion = { name: "Multi select", type: "checkbox" };
export const singleselectQuestion = { name: "Single select", type: "radio" };
export const inputTypes = {
  string: "string",
  image: "img",
};

export const quizClasses = [
  { name: "Pre Nursery", value: "101"},
  { name: "Prep", value: "102"},
  { name: "Nursery", value: "103"},
  { name: "LKG", value: "104"},
  { name: "UKG", value: "105"},
  { name: "1", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
  { name: "5", value: "5" },
  { name: "6", value: "6" },
  { name: "7", value: "7" },
  { name: "8", value: "8" },
  { name: "9", value: "9" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
];

export const minQuestions = 1;
export const minAnswers = 2;
export const placeholderErrorResponse =
  "Something went wrong. Please try later";
export const quizListUrl = "/admin/assessment/quiz/0/1";
export const assessmentListUrl = "/admin/assessment/0";
export const maxLengthTitle = 1000;
export const maxLengthDescription = 4000;
export const maxAnswerLengthLimit = 400;
export const maxQuestionLengthLimit = 1000;
export const fiveMinutesInMillis = 5 * 60 * 1000;
export const countdownRedColor = "#cf1322";

export const reportAnswerTypes = { right: "right", wrong: "wrong" };

export const imageSize = "70px";

export const indexOptionDict = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
};

export const events = {
  start: "start",
  timeout: "timeout",
  submitAnswer: "submitAnswer",
  submitAssessment: "submitAssessment",
  linkOpen: "linkOpen"
};
