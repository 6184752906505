import { Spin } from "antd";
import React from "react";

import "./shared.css";

export default ({ message }) => {
  return (
    <div className="fullPage">
      <Spin size="large" />
      {message}
    </div>
  );
};
