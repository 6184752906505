import React from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Typography, Image } from "antd";

import "./ReportAnswer.css";
import {
  reportAnswerTypes,
  inputTypes,
  imageSize,
} from "../../../utils/constants";
import { serveImage } from "../../../utils/apiDict";

const { Text } = Typography;

const ReportAnswer = ({ type, selectedAnswer, items }) => {
  const answerText = [];
  const answerImages = [];
  items.forEach(({ type: itemType, value }) => {
    if (itemType === inputTypes.image) {
      answerImages.push(value);
    } else {
      answerText.push(value);
    }
  });
  let icon = null;
  let answerClass = "";
  if (type === reportAnswerTypes.right) {
    answerClass = "correct-answer";
    icon = (
      <span className="tick-sign">
        <CheckCircleOutlined className="tickSignIcon" />
      </span>
    );
  } else if (selectedAnswer) {
    answerClass = "wrong-answer";
  }
  return (
    <>
      <ul className="answer-list">
        <li
          className={`${answerClass} ${selectedAnswer ? "student-answer" : ""}`}
        >
          {icon}
          <Text>{answerText}</Text>
          {answerImages.length > 0 && (
            <ul className="thumbnail-images">
              {answerImages.map((url) => (
                <li>
                  <Image
                    className="thumbnail"
                    src={`${process.env.REACT_APP_API_SERVER}${serveImage}${url}/${imageSize}`}
                    key={url}
                    fallback={`${process.env.PUBLIC_URL}/placeholder.png`}
                  />
                </li>
              ))}
            </ul>
          )}
        </li>
      </ul>
    </>
  );
};

export default ReportAnswer;
