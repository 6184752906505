import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Row, Col, PageHeader, Card, Skeleton, Alert } from "antd";
import { useLocation } from "react-router-dom";
import { DateTime } from "luxon";

import Error from "../shared/Error";
import { get, post } from "../../utils/networkUtils";
import {
  quiz as quizApi,
  assessment as assessmentApi,
} from "../../utils/apiDict";
import AssessmentForm from "../shared/AssessmentForm/AssessmentForm";
import { assessmentListUrl } from "../../utils/constants";

const parseQuizData = ({ quizResponseData }) => {
  const parsedData = { ...quizResponseData };
  parsedData.endTime = DateTime.fromISO(parsedData.startTime).plus({
    minutes: parsedData.duration,
  });
  return parsedData;
};

const createAssessment = async ({
  formData,
  quizId,
  setSubmitError,
  setSubmitSuccess,
  setSubmitLoading,
}) => {
  const dateFormat = "yyyy-MM-dd HH:mm:ss";
  setSubmitLoading(true);
  const startDateTime = DateTime.fromFormat(
    `${formData.startDate.format("YYYY-MM-DD")} ${formData.startTime.format(
      "HH:mm:ss"
    )}`,
    dateFormat
  );
  const postData = {
    title: formData.title,
    subject: formData.subject,
    groupIds: formData.groupIds,
    notificationTime: startDateTime
      .minus({ minutes: formData.notificationTime })
      .toFormat(dateFormat),
    startTime: startDateTime.toFormat(dateFormat),
    endTime: startDateTime
      .plus({ minutes: formData.duration })
      .toFormat(dateFormat),
    quizId,
  };
  if (formData.description) {
    postData.description = formData.description;
  }
  setSubmitError("");
  setSubmitSuccess("");
  const postResponse = await post({
    url: `${assessmentApi.replace(":aid/:uid", "")}`,
    body: postData,
    config: { withCredentials: true },
  });
  setSubmitLoading(false);
  if (postResponse.ok) {
    setSubmitSuccess("Assessment created");
    setTimeout(() => {
      window.location = `${process.env.REACT_APP_UOLO_WEB}${assessmentListUrl}`;
      setSubmitSuccess("");
    }, 3000);
  } else {
    setSubmitError(postResponse);
  }
};

const getQuizData = async ({ quizId, setError, setLoading, setQuizData }) => {
  try {
    let quizResponse = await get({
      url: `${quizApi}/${quizId}`,
      config: { withCredentials: true },
    });
    quizResponse = quizResponse.data;
    if ((quizResponse || {}).data) {
      setQuizData(
        parseQuizData({
          quizResponseData: (quizResponse || {}).data,
        })
      );
    } else {
      throw quizResponse.err || "some error occured. please try later";
    }
    setLoading(false);
  } catch (e) {
    setError(e.message || e);
  }
};

const AssessmentCreate = () => {
  const location = useLocation();
  const quizId = new URLSearchParams(location.search).get("aqid");
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [quizData, setQuizData] = useState({});
  const onSubmit = (values) => {
    createAssessment({
      formData: values,
      quizId,
      setSubmitError,
      setSubmitSuccess,
      setSubmitLoading,
    });
  };
  useEffect(() => {
    getQuizData({ quizId, setLoading, setError, setQuizData });
  }, [quizId]);
  return (
    <>
      {error && <Error error={error} />}
      {!error && (
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 16, offset: 4 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 8, offset: 8 }}
          >
            <PageHeader title="Create Assessment" />
            {loading && <Skeleton />}
            {!loading && (
              <Card>
                <AssessmentForm
                  formData={quizData}
                  onSubmitForm={onSubmit}
                  loading={submitLoading}
                />
                {!loading && submitError && (
                  <Alert type="error" message={submitError} showIcon />
                )}
                {!loading && submitSuccess && (
                  <Alert type="success" message={submitSuccess} showIcon />
                )}
              </Card>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default AssessmentCreate;
