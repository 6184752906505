/* eslint-disable react/no-array-index-key */
import { CloseOutlined } from "@ant-design/icons";
import { Col, Progress, Row, Tooltip } from "antd";
import React, { useState, useEffect, useRef } from "react";

import AwesomeCard from "./AwesomeCard";
import TryAgainCard from "./TryAgainCard";
import RecordingCard from "./RecordingCard";
import TapAndRecordCard from "./TapAndRecordCard";
import TextResult from "./TextResult";
import { analyzeAudio } from "../../utils/apiDict";
import "antd/dist/antd.css";

const PlayScreenSpeaking = ({
  questionNumber,
  totalQuestions,
  question,
  image,
  audio,
  leadId,
  nextQuestion,
  updateCorrectAnswerCount,
  reset,
  instructions,
  grade,
}) => {
  const mediaRecorder = useRef();
  const chunks = useRef();
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [resultPercent, setResultPercent] = useState(100);
  const [showResult, setShowResult] = useState(false);
  const [textResult, setTextResult] = useState();
  const [micAccess, setMicAccess] = useState(false);
  const [analysing, setAnalysing] = useState(false);
  const [voiceRecording, setVoiceRecording] = useState();
  const timer = useRef();
  const resultRef = useRef();
  const headRef = useRef();
  let speaker;

  const init = async () => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    if (navigator.mediaDevices) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicAccess(true);
      mediaRecorder.current = new MediaRecorder(stream);
      chunks.current = [];
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data && e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
    } else {
      throw new Error("Update browser to latest version to continue.");
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (showResult && resultRef.current) {
      resultRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showResult]);

  const onRecodingStatusChange = () => {
    if (micAccess) {
      if (speaker) {
        speaker.pause();
      }
      if (isRecording) {
        setAnalysing(true);
        setIsRecording(false);
      } else if (!isRecording && !analysing) setIsRecording(true);
    } else {
      init();
    }
  };

  const startRecording = async () => {
    if (mediaRecorder.current !== undefined) {
      chunks.current = [];
      mediaRecorder.current.start(100);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current !== undefined) mediaRecorder.current.stop();
  };

  const uploadRecording = () => {
    if (chunks.current.length) {
      const blob = new Blob(chunks.current, { type: "audio/mpeg" });
      const body = new FormData();
      body.append("text", question);
      body.append("audio", blob);
      body.append("questionNo", questionNumber);
      body.append("leadId", leadId);

      fetch(`${process.env.REACT_APP_API_SERVER}${analyzeAudio}`, {
        body,
        headers: { Authorization: process.env.REACT_APP_QUIZ_AUTHORIZATION },
        method: "POST",
      })
        .then((response) =>
          response.json().then((data) => {
            setAnalysing(false);
            setTextResult(data.data.words);
            setVoiceRecording(blob);
            setShowResult(true);
            setResultPercent(data.data.score);
            if (data.data.score >= 65) {
              updateCorrectAnswerCount();
            }
          })
        )
        .catch(() => setAnalysing(false));
    }
  };

  const startTimer = () => {
    timer.current = setInterval(() => {
      setRecordingTime((prevState) => {
        return prevState + 1;
      });
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timer.current);
    setRecordingTime(0);
  };

  const getNextQuestion = () => {
    if (speaker) {
      speaker.pause();
    }
    setShowResult(false);
    nextQuestion();
    if (headRef.current) {
      headRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (isRecording) {
      startRecording();
      startTimer();
    }
    if (!isRecording) {
      stopRecording();
      if (chunks.current) {
        uploadRecording();
      }
      stopTimer();
    }
  }, [isRecording]);

  const textList = [];
  if (textResult) {
    question.split(" ").forEach((word, index) => {
      if (word === "\n") textList.push(<br />);
      else {
        for (let i = 0; i < textResult.length; i++) {
          let result = textResult[i];
          if (word.replace(/[^a-zA-Z ]/g, "") == result.text) {
            textList.push(
              <TextResult
                score={result.score}
                text={word}
                key={index}
                voiceRecording={voiceRecording}
                startTime={result.startTime}
                endTime={result.endTime}
              />
            );
            break;
          }
        }
      }
    });
  }

  const speakText = () => {
    if (speaker) {
      speaker.pause();
    }
    if (!analysing && !isRecording) {
      speaker = new Audio(audio);
      speaker.play();
    }
  };

  return (
    <Col
      style={{ backgroundColor: "#fff7e5", minHeight: "100vh" }}
      ref={headRef}
    >
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 16, offset: 4 }}
        lg={{ span: 12, offset: 6 }}
        xl={{ span: 8, offset: 8 }}
      >
        <Row className="pt18" justify="center">
          <Col span={2}>
            <span>
              {questionNumber}/{totalQuestions}
            </span>
          </Col>
          <Col span={17}>
            <Progress
              percent={(100 * questionNumber) / totalQuestions}
              showInfo={false}
              trailColor="#b7b2a7"
              strokeColor="#3253ce"
              className="center"
            />
          </Col>
          <Col span={3} className="mt5 cursorpointer" onClick={reset}>
            <CloseOutlined className="center" />
          </Col>
        </Row>
        <Col style={{ margin: "22px" }}>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <span
              style={{
                color: "rgb(86, 31, 231)",
                fontSize: 22,
                fontWeight: 600,
                fontFamily: "Outfit",
                fontStyle: "normal",
              }}
            >
              {grade}
            </span>
          </Row>
          <Row
            color="#fdc648"
            justify="center"
            style={{ backgroundColor: "#f6f6f6" }}
          >
            <span
              style={{ fontSize: "16px", fontWeight: "600", margin: "14px 0" }}
            >
              {instructions}
            </span>
          </Row>
          <Row color="#fdc648" justify="center">
            <Col>
              <img
                src={process.env.PUBLIC_URL + image}
                style={{ width: "100%" }}
                alt=""
              />
            </Col>
          </Row>
          <Row
            style={{
              border: "1px solid #e3e3e3",
              borderRadius: "0px 0px 12px 12px",
              borderTopWidth: "0px",
              backgroundColor: "white",
            }}
            className="pt18 pb10"
          >
            <Col span={2} offset={1} onClick={speakText}>
              <img
                src="/playquiz/Speaker.svg"
                alt="Listen"
                className="center"
              />
            </Col>
            <Col span={19} offset={1} className="breakword">
              {showResult ? (
                textList
              ) : (
                <p
                  className="question-text"
                  dangerouslySetInnerHTML={{
                    __html: question.replace(/\n/g, "<br />"),
                  }}
                ></p>
              )}
            </Col>
          </Row>
          {showResult ? (
            <Row
              align="bottom"
              style={{
                marginTop: "60px",
              }}
              className="ml16 mr16"
              ref={resultRef}
            >
              {resultPercent >= 65 ? (
                <AwesomeCard onClick={getNextQuestion} />
              ) : (
                <TryAgainCard onClick={getNextQuestion} />
              )}
            </Row>
          ) : (
            <Tooltip
              title={micAccess ? "" : "Need Microphone access to continue."}
            >
              <Row
                style={{
                  marginTop: "60px",
                  border: "1px dotted black",
                  borderRadius: "8px",
                  backgroundColor: "#f9f6ff",
                }}
                className="ml16 mr16 cursorpointer"
                onClick={onRecodingStatusChange}
              >
                {isRecording ? (
                  <RecordingCard timer={recordingTime} />
                ) : (
                  <TapAndRecordCard isAnalysing={analysing} />
                )}
              </Row>
            </Tooltip>
          )}
        </Col>
      </Col>
    </Col>
  );
};

export default PlayScreenSpeaking;
