/* eslint react/prop-types: 0 */
import React from "react";
import { Form, Typography, Input, Select, InputNumber, Row, Col } from "antd";

import RenderInput from "./RenderInput";
import { quizClasses } from "../../utils/constants";

const { Text } = Typography;
const { Option } = Select;
const QuizMetadataForm = ({ formData, deleteData }) => {
  return (
    <>
      <Text>Title</Text>
      <RenderInput
        items={((formData || {}).title || {}).items}
        key={formData.id}
        deleteData={deleteData}
        itemElement="form"
      />
      <Form.Item name="description" label="Description">
        <Input.TextArea size="default" />
      </Form.Item>
      <Form.Item
        name="subject"
        label="Subject"
        rules={[
          {
            required: true,
            message: "Please enter some text",
          },
        ]}
        required={false}
      >
        <Input size="default" />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="duration"
            label="Duration (in minutes)"
            rules={[
              {
                required: true,
                message: "Please enter duration",
              },
            ]}
            required={false}
          >
            <InputNumber
              style={{ width: "100%" }}
              size="default"
              min={1}
              max={8000}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="class" label="Class">
            <Select>
              {quizClasses.map((classObject) => (
                <Option value={classObject.value} key={classObject.name}>
                  {classObject.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default QuizMetadataForm;
