import React, { useEffect, useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import { Button, Popconfirm, message } from "antd";
import { DateTime } from "luxon";
import usePublishStudentsAssessmentReport from "../../hooks/usePublishStudentsAssessmentReport";

const PublishButton = ({
  assessmentId,
  lastestPublishTime,
  setPublishTime,
}) => {
  const { loading, error, publishReport } = usePublishStudentsAssessmentReport({
    assessmentId,
  });
  const [success, setSuccess] = useState("");
  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);
  useEffect(() => {
    if (success) {
      message.success(success);
      setSuccess("");
    }
  }, [success]);
  return (
    <>
      {lastestPublishTime && (
        <Popconfirm
          title={`The Students’ Report was last published on ${DateTime.fromISO(
            lastestPublishTime
          ).toFormat("dd-MMM-yyyy HH:mm")}. Are you sure you want to resend?`}
          okText="Resend"
          okType="primary"
          onConfirm={async () => {
            const submitted = await publishReport();
            if (submitted.ok && submitted.data.ok) {
              setPublishTime(new Date());
              setSuccess("Students' report sent");
            }
          }}
          cancelText="Cancel"
        >
          <Button icon={<SendOutlined />} loading={loading} type="primary">
            Send Students&apos; Report
          </Button>
        </Popconfirm>
      )}
      {!lastestPublishTime && (
        <Button
          onClick={async () => {
            const submitted = await publishReport();
            if (submitted.ok && submitted.data.ok) {
              setPublishTime(new Date());
              setSuccess("Students' report sent");
            }
          }}
          icon={<SendOutlined />}
          loading={loading}
          type="primary"
        >
          Send Students&apos; Report
        </Button>
      )}
    </>
  );
};

export default PublishButton;
