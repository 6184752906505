import { RedoOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";

const QuizCompleted = ({ totalCorrect, totalQuestions, retry }) => {
  return (
    <Col style={{ backgroundColor: "#fff7e5", minHeight: "100vh" }}>
      <Row justify="center">
        <img
          src="/uoloLogo.svg"
          alt="UOLO"
          style={{ height: "34px", margin: "25px 0" }}
        />
      </Row>
      <Row justify="center" style={{ marginBottom: "46px" }}>
        <img
          src="/playquiz/quiz_completed.svg"
          style={{ height: "144px", width: "144px", marginTop: "50px" }}
          alt="Quiz Completed"
        />
      </Row>
      <Row justify="center">
        <span className="fsz32 fw800 text-align-center">Quiz Completed</span>
      </Row>
      <Row justify="center">
        <span className="fsz24 fw600">
          Your score {totalCorrect}/{totalQuestions}.
        </span>
      </Row>
      <Row justify="center">
        <Col
          xs={{ span: 15 }}
          sm={{ span: 12 }}
          md={{ span: 10 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
          className="cursorpointer"
          onClick={retry}
          style={{
            backgroundColor: "#5A39D6",
            height: 50,
            width: "90%",
            margin: "20px 30px 20px 30px",
            boxShadow: "0px 4px 0px #4224B3",
            borderRadius: "14px",
          }}
        >
          <p
            className="fsz20 fw600 center"
            style={{
              color: "white",
              fontFamily: "Outfit",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 18,
              letterSpacing: "0.04em",
            }}
          >
            <RedoOutlined />
            Retry
          </p>
        </Col>
      </Row>
    </Col>
  );
};

export default QuizCompleted;
