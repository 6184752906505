import React from "react";
import { useForm } from "antd/lib/form/Form";
import { Form, Button, Typography, Divider } from "antd";
import { LeftOutlined, SaveOutlined, RightOutlined } from "@ant-design/icons";

import Header from "./Header";
import ReloadableImage from "./ReloadableImage";
import FieldInput from "./types/FieldInput";

import "./shared.css";

export default ({
  field,
  index,
  total,
  save,
  next,
  prev,
  preview,
  solution,
}) => {
  const [form] = useForm();
  const lastQuestion = index === total;

  const finish = (data) => {
    const answer = {};
    Object.keys(data).forEach((key) => {
      answer[key] = data[key].value;
    });
    save(answer);
    next();
  };

  return (
    <Form form={form} onFinish={finish}>
      <Header
        text={`Question ${index}/${total}`}
        subtitle={`Marks: ${field.score}`}
      />
      <div style={{ marginTop: "15px", padding: "0px 10px" }}>
        {field.title.items.map(({ id, type, value }) => {
          switch (type) {
            case "string":
              return (
                <Typography.Paragraph key={id}>{value}</Typography.Paragraph>
              );
            case "img":
              return (
                <ReloadableImage
                  className="displayImage"
                  value={value}
                  key={id}
                />
              );
            default:
              return null;
          }
        })}
      </div>
      <Divider />
      <FieldInput {...field} solution={solution} preview={preview} />
      <Divider />
      <Button
        icon={<LeftOutlined />}
        style={{ marginLeft: "5px" }}
        onClick={() => {
          prev();
          window.scrollTo(0, 0);
        }}
      >
        Previous
      </Button>
      <Button
        style={{
          float: "right",
          marginRight: "5px",
          display: preview && lastQuestion && "none",
        }}
        onClick={() => {
          form.submit();
          window.scrollTo(0, 0);
        }}
        type={lastQuestion ? "primary" : ""}
      >
        {lastQuestion ? "Submit" : "Next"}
        {lastQuestion ? <SaveOutlined /> : <RightOutlined />}
      </Button>
    </Form>
  );
};
