/* eslint react/prop-types: 0 */
// @todo anupam - add prop types
import React from "react";
import { Button, Tooltip, Form, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import RenderImageUpload from "./RenderImageUpload";
import { serveImage as imageServeUrl } from "../../utils/apiDict";
import {
  maxQuestionLengthLimit,
  maxAnswerLengthLimit,
} from "../../utils/constants";

import "./QuizCreate.css";

const inputRules = ({ itemElement }) => {
  const rules = [{ required: true, message: "Please enter some text" }];
  if (itemElement === "option") {
    rules.push({
      max: maxAnswerLengthLimit,
      message: `The answer option cannot exceed ${maxAnswerLengthLimit} characters`,
    });
    return rules;
  }
  if (itemElement === "input") {
    rules.push({
      max: maxQuestionLengthLimit,
      message: `The question title cannot exceed ${maxQuestionLengthLimit} characters`,
    });
    return rules;
  }
  return rules;
};

const ImageRender = ({ src, onCrossClick }) => (
  <div className="imageContainer">
    <Tooltip title="Remove Image">
      {onCrossClick && (
        <Button
          className="overlay"
          icon={<CloseOutlined />}
          shape="circle"
          type="primary"
          danger
          size="small"
          onClick={onCrossClick}
        />
      )}
    </Tooltip>
    <img
      className="optionImage"
      src={`${process.env.REACT_APP_API_SERVER}${imageServeUrl}${src}`}
      alt=""
    />
  </div>
);

const RenderInput = ({
  inputId,
  items,
  itemElement,
  deleteData,
  renderImageUpload,
  addItemInInput,
}) => {
  const isImageInputPresent = items.some(({ type }) => type === "img");
  return (
    <>
      {items.map(({ type, value, id }) => {
        let returnObj = null;
        if (type === "img") {
          returnObj = (
            <ImageRender
              src={value}
              key={id}
              onCrossClick={
                deleteData &&
                (() => {
                  deleteData({ id, element: "item", itemElement });
                })
              }
            />
          );
        } else {
          returnObj = (
            <React.Fragment key={id}>
              <div className="inputContainer">
                <div>
                  <Form.Item
                    name={id}
                    rules={inputRules({ itemElement })}
                    value={value}
                    key={id}
                  >
                    <Input size="default" />
                  </Form.Item>
                </div>
                {renderImageUpload && !isImageInputPresent ? (
                  <div className="addImageButton">
                    <RenderImageUpload
                      onUpload={addItemInInput}
                      inputId={inputId}
                    />
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          );
        }
        return returnObj;
      })}
    </>
  );
};

export default RenderInput;
