import { create } from "apisauce";

const api = create({
  baseURL: process.env.REACT_APP_API_SERVER,
});

export const get = ({ url, params, config }) => {
  return api.get(url, params, config);
};

export const post = ({ url, body = {}, config }) => {
  return api.post(url, body, config);
};

export const put = ({ url, body, config }) => {
  return api.put(url, body, config);
};

export const del = ({ url, params, config }) => {
  return api.delete(url, params, config);
};
