/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import WelcomeScreen from "./WelcomeScreen";
import PlayScreenSpeaking from "./PlayScreenSpeaking";
import QuizCompleted from "./QuizCompleted";
import { loadQuizWithLeadId as loadQuiz } from "../../utils/apiDict";
import PlayScreenListening from "./PlayScreenListening";
import PlayScreenGrammar from "./PlayScreenGrammar";

const questionListSpeaking = [
  {
    text: "What brings you here, old man? Tell me without fear, and I promise you that justice will be done.",
    number: 1,
    image:
      "https://m.uolo.co/media/images/ZDUwYTk0ZmEtYzMxMi00OWVjLThkNDktZjdjMTc3YmViZDkxLmpwZw.jpg",
    audio:
      "https://m.uolo.co/media/audio/OTYxZmZhZjctZGU0NC00OTdjLWFjZTEtZGEzZmQ3MDg5OGQxLm1wMw.mp3",
    instructions: "Read aloud with expressions.",
    grade: "Grade 3",
  },
  {
    text: `Neha: Hi Tanisha, how are you? \n Tanisha: I am good, what about you dear? \n Neha: I am fine. Can I ask you something? What's your favourite hobby? \n Tanisha: I love gardening! My parents help me take care of the plants in my small kitchen garden. \n Neha: Wow, that's great! I would like to visit your garden too. \n Tanisha: You are most welcome. \n`,
    number: 2,
    image:
      "https://m.uolo.co/media/images/YWMyNmM0MmMtYTg4Mi00NGU5LWIxN2EtM2IwZTVhNDE1NzU2LmpwZw.jpg",
    audio:
      "https://m.uolo.co/media/audio/OGQwODQ1ZDQtODllZS00MDk0LTk2YTAtNjRiZmQ4YTdlMGJiLm1wMw.mp3",
    instructions: "Read aloud.",
    grade: "Grade 3",
  },
  {
    text: "Deforestation refers to the decrease in forest areas across the world for agricultural croplands, growing cities or mining activities.",
    number: 3,
    image:
      "https://m.uolo.co/media/images/YmEzNWJiNTEtMGQxMS00MzU2LTg0ZDgtMzlhODgyMjdlMDAxLmpwZw.jpg",
    audio:
      "https://m.uolo.co/media/audio/YmU1YmE3MmQtZTMzYS00NzA1LWJiNzktNDgxZjdiNTE1OGEyLm1wMw.mp3",
    instructions:
      "Read aloud the response to the question: What is deforestation?",
    grade: "Grade 5",
  },
  {
    text: "“The class picnic was exciting! We went to a park and saw butterflies there. I shared food with my friends and played games. We also sang songs.”",
    number: 4,
    image:
      "https://m.uolo.co/media/images/ODlmYTAxOTgtZWNhMC00YThiLWE4ZWYtYTVjMjExZDZhZDFhLmpwZw.jpg",
    audio:
      "https://m.uolo.co/media/audio/YmRkYzFkMmUtN2FlZi00Yjg0LTgzMGEtYzg4NDk5Y2M3OGYwLm1wMw.mp3",
    instructions:
      "Read aloud Ritwik’s reply to his mother’s question: “How did the school picnic go?”",
    grade: "Grade 5",
  },
  {
    text: "He is an Indian mathematician who learned enough math to be in a college when he was eleven. He was creating his own theorems when he was thirteen. He is Srinivasan Ramanujan.",
    number: 5,
    image:
      "https://m.uolo.co/media/images/YjNjMGQ5OTYtYzNjNC00MzEzLThjZDUtZTUyNzRlYzE0ODgyLmpwZw.jpg",
    audio:
      "https://m.uolo.co/media/audio/ZDNmYTRhNWYtY2M2NC00YTkxLThiZTEtY2IwOGI4YWZjZDAxLm1wMw.mp3",
    instructions: "Say aloud.",
    grade: "Grade 7",
  },
  {
    text: "I still remember Mohan. He was the brightest boy in my class. He came from a really poor family, and couldn’t afford to buy new notebooks every year.",
    number: 6,
    image:
      "https://m.uolo.co/media/images/NTYwOTUwM2ItYjg0My00MWZmLWFjNTEtNTdkMTY4M2RlYTE4LmpwZw.jpg",
    audio:
      "https://m.uolo.co/media/audio/MzdkZDU4OTctMGFkZC00MDcwLWJiNjAtOGY3MzdmZmY3NjA4Lm1wMw.mp3",
    instructions: "Say aloud with correct expressions.",
    grade: "Grade 7",
  },
];

const questionListGrammar = [
  {
    question: "_____ you catch the bus yesterday?",
    number: 1,
    answer: "Did",
    options: ["Did", "Do", "Will", "Don’t"],
    grade: "Grade 3",
  },
  {
    question: "The ____ boy came first in the final exam.",
    number: 2,
    answer: "new",
    options: ["new", "few", "many", "some"],
    grade: "Grade 3",
  },
  {
    question:
      "I bought _____ of these chandeliers from the shop around the corner.",
    number: 3,
    answer: "each",
    options: ["either", "anyone", "everyone", "each"],
    grade: "Grade 5",
  },
  {
    question:
      "Can you point out who John is? _____ is him – the boy sitting right at the back.",
    number: 4,
    answer: "That",
    options: ["This", "That", "These", "Those"],
    grade: "Grade 5",
  },
  {
    question:
      "Anagha got a box full of hand-embroidered ______ from her grandmother.",
    number: 5,
    answer: "handkerchiefs",
    options: [
      "handkerchives",
      "handkerchiefs",
      "handkerchive",
      "handkerchieve",
    ],
    grade: "Grade 7",
  },
  {
    question:
      "_____ her gruelling routine and lack of enough support staff, Shikha continued to go to the hospital.",
    number: 6,
    answer: "In spite of",
    options: ["Although", "In spite of", "Provided that", "As soon as"],
    grade: "Grade 7",
  },
];

const questionListListening = [
  {
    question: "What do you hear?",
    number: 1,
    answer: "scream",
    options: ["icecream", "cream", "scream", "screem"],
    audio:
      "https://m.uolo.co/media/audio/YTFkYTgxNDItMGNlOC00Y2NiLTk1ZGEtNjdlOGU5MWYyYTViLm1wMw.mp3",
    grade: "Grade 3",
  },
  {
    question: "What do you hear?",
    number: 2,
    answer: "Meera, Shivam and I are going to sing at the school fair.",
    options: [
      "meera shivam and i are going to sing at the school fair",
      "Meera Shivam and i are going to sing at the school fair",
      "Meera, Shivam and I are going to sing at the school fair.",
      "Meera, Shivam, and i are going to sing at the school fair.",
    ],
    audio:
      "https://m.uolo.co/media/audio/M2Y1ZjUwM2QtMzhlZi00Y2I3LTkwOTEtNGNhMTgyZjhlN2QxLm1wMw.mp3",
    grade: "Grade 3",
  },
  {
    question: "Identify the type of the text.",
    number: 3,
    answer: "Biography",
    options: ["Biography", "Travel journal", "Advertisement", "Diary entry"],
    audio:
      "https://m.uolo.co/media/audio/N2YxYmZkYTktNmQ2Mi00OWVmLWE0YzAtZmNmOTNmZjI0YTdjLm1wMw.mp3",
    grade: "Grade 5",
  },
  {
    question: "Listen to the audio and fill in the blank.",
    number: 4,
    answer: "These",
    options: ["That", "Those", "These", "This"],
    audio:
      "https://m.uolo.co/media/audio/ZmJkY2ZmMmQtNzQ5NC00ZmRjLWJjNWUtYmJjNWMzY2UxOWQwLm1wMw.mp3",
    grade: "Grade 5",
  },
  {
    question: "Complete the paragraph using appropriate words.",
    number: 5,
    answer: "attention / impending / struggled",
    options: [
      "heed / ongoing / longed",
      "attention / impounding / confiscate",
      "attention / impending / struggled",
      "attention / impended / struggles",
    ],
    audio:
      "https://m.uolo.co/media/audio/ODA3NGYyMzktNzk5My00ZDdmLWI1MWItZGY5MzllNDY1MzQwLm1wMw.mp3",
    grade: "Grade 7",
  },
  {
    question: "What is this set of instructions for?",
    number: 6,
    answer: "To make an origami flower",
    options: [
      "To make an origami airplane",
      "To make an origami flower",
      "To make an origami boat",
      "To make an origami heart",
    ],
    audio:
      "https://m.uolo.co/media/audio/YzIwNGNkOTItY2VhZi00NDE0LWFhY2UtN2M4NGVkZWExMzBlLm1wMw.mp3",
    grade: "Grade 7",
  },
];

const DemoQuiz = () => {
  const { leadId } = useParams();
  const [questionIndex, setQuestionIndex] = useState(
    parseInt(sessionStorage.getItem("questionIndex"), 10) || 0
  );
  const [correctAnswerCount, setCorrectAnswerCount] = useState(
    parseInt(sessionStorage.getItem("correctAnswerCount"), 10) || 0
  );
  const nextQuestion = () => {
    setQuestionIndex(questionIndex + 1);
    sessionStorage.setItem("questionIndex", questionIndex + 1);
  };

  const updateCorrectAnswerCount = () => {
    sessionStorage.setItem("correctAnswerCount", correctAnswerCount + 1);
    setCorrectAnswerCount(correctAnswerCount + 1);
  };
  const [playQuiz, setPlayQuiz] = useState(false);
  const [quizType, setQuizType] = useState("");

  const reset = () => {
    setPlayQuiz(false);
    setQuestionIndex(0);
    setCorrectAnswerCount(0);
    sessionStorage.setItem("questionIndex", 0);
    sessionStorage.setItem("correctAnswerCount", 0);
    setQuizType(null);
  };
  const startQuiz = () => {
    fetch(`${process.env.REACT_APP_API_SERVER}${loadQuiz}${leadId}`, {
      headers: { Authorization: process.env.REACT_APP_QUIZ_AUTHORIZATION },
      method: "PUT",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) setPlayQuiz(true);
      });
  };

  if (!playQuiz) {
    return (
      <WelcomeScreen
        startQuiz={startQuiz}
        setQuizType={setQuizType}
        quizType={quizType}
      />
    );
  }
  if (
    quizType === "speaking" &&
    questionIndex === questionListSpeaking.length
  ) {
    sessionStorage.setItem("questionIndex", 0);
    sessionStorage.setItem("correctAnswerCount", 0);
    return (
      <QuizCompleted
        totalQuestions={questionListSpeaking.length}
        totalCorrect={correctAnswerCount}
        retry={reset}
      />
    );
  }

  if (quizType === "grammar" && questionIndex === questionListGrammar.length) {
    sessionStorage.setItem("questionIndex", 0);
    sessionStorage.setItem("correctAnswerCount", 0);
    return (
      <QuizCompleted
        totalQuestions={questionListGrammar.length}
        totalCorrect={correctAnswerCount}
        retry={reset}
      />
    );
  }

  if (
    quizType === "listening" &&
    questionIndex === questionListListening.length
  ) {
    sessionStorage.setItem("questionIndex", 0);
    sessionStorage.setItem("correctAnswerCount", 0);
    return (
      <QuizCompleted
        totalQuestions={questionListListening.length}
        totalCorrect={correctAnswerCount}
        retry={reset}
      />
    );
  }
  return (
    <div>
      {quizType === "speaking" ? (
        <PlayScreenSpeaking
          questionNumber={questionListSpeaking[questionIndex].number}
          totalQuestions={questionListSpeaking.length}
          question={questionListSpeaking[questionIndex].text}
          image={questionListSpeaking[questionIndex].image}
          audio={questionListSpeaking[questionIndex].audio}
          instructions={questionListSpeaking[questionIndex].instructions}
          grade={questionListSpeaking[questionIndex].grade}
          leadId={leadId}
          nextQuestion={nextQuestion}
          updateCorrectAnswerCount={updateCorrectAnswerCount}
          reset={reset}
        />
      ) : quizType === "listening" ? (
        <PlayScreenListening
          questionNumber={questionListListening[questionIndex].number}
          totalQuestions={questionListListening.length}
          question={questionListListening[questionIndex].question}
          answer={questionListListening[questionIndex].answer}
          updateCorrectAnswerCount={updateCorrectAnswerCount}
          nextQuestion={nextQuestion}
          reset={reset}
          options={questionListListening[questionIndex].options}
          audio={questionListListening[questionIndex].audio}
          grade={questionListListening[questionIndex].grade}
        />
      ) : quizType === "grammar" ? (
        <PlayScreenGrammar
          questionNumber={questionListGrammar[questionIndex].number}
          totalQuestions={questionListGrammar.length}
          question={questionListGrammar[questionIndex].question}
          answer={questionListGrammar[questionIndex].answer}
          updateCorrectAnswerCount={updateCorrectAnswerCount}
          nextQuestion={nextQuestion}
          reset={reset}
          options={questionListGrammar[questionIndex].options}
          grade={questionListGrammar[questionIndex].grade}
        />
      ) : null}
    </div>
  );
};

export default DemoQuiz;
