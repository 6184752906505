import React from "react";
import { Steps } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";

const { Step } = Steps;

const Header = ({ text, subtitle }) => (
  <div className="headerContainer">
    <Steps size="small">
      <Step title={text} subTitle={subtitle} icon={<UnorderedListOutlined />} />
    </Steps>
  </div>
);

export default Header;
