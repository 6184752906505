import { useState, useEffect } from "react";

import { get } from "../utils/networkUtils";
import { quizApi } from "../utils/apiDict";

const useQuizDetails = ({ quizId, ref }) => {
  const [loading, setLoading] = useState(true);
  const [quizDetails, setQuizDetails] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (ref.current && quizId) {
      (async () => {
        const res = await get({
          url: quizApi.replace(":quizId", quizId),
          config: { withCredentials: true },
        });
        if (res.ok) {
          setQuizDetails(res.data);
        } else {
          setError(res.problem);
        }
        setLoading(false);
      })();
    }
    if (!quizId) {
      setLoading(false);
    }
    return () => {
      ref.current = false;
    };
  }, [quizId, ref]);

  return { loading, quizDetails, error };
};

export default useQuizDetails;
