import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "antd";

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const TextResult = ({ text, score, voiceRecording, startTime, endTime }) => {
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    if (isClicked) {
      async function playAudio() {
        if (score < 90) {
          const body = JSON.stringify({
            input: {
              text: "you should have said " + text,
            },
            audioConfig: {
              audioEncoding: "MP3",
              pitch: 1,
              speakingRate: 0.8,
            },
            voice: {
              languageCode: "en-IN",
              name: "en-IN-Wavenet-D",
            },
          });
          let blobUrl = "";
          await fetch(
            `https://content-texttospeech.googleapis.com/v1/text:synthesize?alt=json&key=AIzaSyC9q4ma3VsnlTtxfP4Lo0hM8g-b5mL4O6Y`,
            {
              body,
              headers: {},
              method: "POST",
            }
          )
            .then((response) =>
              response.json().then(async (data) => {
                const contentType = "audio/mp3";
                const blob = b64toBlob(data.audioContent, contentType);
                blobUrl = URL.createObjectURL(blob);
              })
            )
            .catch((err) => console.log(err));
          let speaker = await new Audio("/playquiz/you_said.mp3");
          speaker.play();
          speaker.onended = async function () {
            let speaker1 = await new Audio(URL.createObjectURL(voiceRecording));
            speaker1.currentTime = startTime;
            speaker1.play();
            setTimeout(async () => {
              speaker1.pause();
              let speaker2 = await new Audio(blobUrl);
              speaker2.play();
            }, (endTime - startTime) * 1000);
          };
        }
      }
      playAudio();
      setIsClicked(false);
    }
  }, [isClicked]);
  return (
    <>
      <Tooltip
        title={
          score >= 90 ? "You said : " + text : "You should have said : " + text
        }
        trigger={"click"}
      >
        <span
          className={
            score >= 90
              ? "validresult question-text"
              : "invalidresult question-text"
          }
          onClick={() => {
            setIsClicked(true);
          }}
        >
          {text}
        </span>
      </Tooltip>
      &nbsp;
    </>
  );
};

export default TextResult;
