/* eslint react/prop-types: 0 */
// @todo anupam - add prop types
import React, { useState } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Divider,
  Card,
  PageHeader,
  Skeleton,
  Alert,
} from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./QuizCreate.css";

import {
  multiselectQuestion,
  singleselectQuestion,
  inputTypes,
} from "../../utils/constants";
import { checkNaturalNo } from "../../utils/utils";
import Header from "../shared/Header";
import QuizForm from "./QuizForm/QuizForm";

const formMetadataPageNo = 1;
const separatorString = "##";

const getFormState = ({ formData }) => {
  const formFields = [];
  formData.title.items.forEach(({ id, type, value }) => {
    if (type === "string") {
      formFields.push({
        name: [id],
        value,
      });
    }
  });
  (formData.inputs || []).forEach((stru) => {
    stru.title.items.forEach(({ id, type, value }) => {
      if (type === "string") {
        formFields.push({
          name: [id],
          value,
        });
      }
    });
    (stru.options || []).forEach(({ items }) => {
      items.forEach(({ id, type, value }) => {
        if (type === "string") {
          formFields.push({
            name: [id],
            value,
          });
        }
      });
    });
    formFields.push({
      name: [`${stru.id}${separatorString}type`],
      value: stru.type,
    });
    formFields.push({
      name: [`${stru.id}${separatorString}score`],
      value: stru.score,
    });
  });
  ["duration", "description", "subject", "class"].forEach((name) => {
    formFields.push({ name: [name], value: formData[name] });
  });
  return formFields;
};

const getIdsByElements = ({ pageNo, currentFormData, submitFormPageNo }) => {
  let elementIds = {};
  if (![formMetadataPageNo, submitFormPageNo].includes(pageNo)) {
    elementIds = {
      input: [],
      inputTitleItem: [],
      optionItem: [],
    };
    ((currentFormData.title || {}).items || []).forEach((item) => {
      elementIds.inputTitleItem.push(item.id);
    });
    elementIds.input.push(currentFormData.id);
    ((currentFormData || {}).options || []).forEach((option) => {
      option.items.forEach((item) => {
        elementIds.optionItem.push(item.id);
      });
    });
  } else if (Object.keys(currentFormData || {}).length) {
    // getting form title item ids
    elementIds = { formItem: [] };
    currentFormData.title.items.forEach((item) => {
      elementIds.formItem.push(item.id);
    });
  }
  return elementIds;
};

const updateForm = ({ elementIdGroups, updatedFields, updateData }) => {
  updatedFields.forEach((field) => {
    const elementId = field.name[0];
    if ((elementIdGroups.inputTitleItem || []).includes(elementId)) {
      updateData({
        id: elementId,
        element: "title",
        titleElement: "input",
        data: { type: inputTypes.string, value: field.value },
      });
    } else if ((elementIdGroups.optionItem || []).includes(elementId)) {
      updateData({
        id: elementId,
        element: "option",
        data: { type: inputTypes.string, value: field.value },
      });
    } else if ((elementIdGroups.formItem || []).includes(elementId)) {
      updateData({
        id: elementId,
        element: "title",
        titleElement: "form",
        data: { type: inputTypes.string, value: field.value },
      });
    } else if (["description", "subject", "class"].includes(elementId)) {
      updateData({
        element: elementId,
        data: { value: field.value },
      });
    } else if (
      elementId === "duration" &&
      checkNaturalNo({ value: `${field.value}` })
    ) {
      updateData({
        element: elementId,
        data: { value: field.value },
      });
    } else {
      const [inputId, updateType] = elementId.split(separatorString);
      if (updateType) {
        const updateObj = {
          id: inputId,
          element: "input",
          data: {},
        };
        // field.value is wrapped in string to convert integer into
        if (
          updateType === "score" &&
          checkNaturalNo({ value: `${field.value}` })
        ) {
          updateObj.data.score = field.value;
          updateData(updateObj);
        } else if (updateType === "type") {
          updateObj.data.type = field.value;
          updateData(updateObj);
        }
      }
    }
  });
};

const getFormPerPage = ({ pageNo, formData }) => {
  let perPageData = null;
  let finalPageNo = pageNo;
  const questionsArray = (formData || {}).inputs || [];
  const totalPages = 2 + questionsArray.length;
  if (pageNo === formMetadataPageNo) {
    perPageData = { ...formData, inputs: null };
  } else if (pageNo !== totalPages) {
    const questionIndex = pageNo - 2;
    perPageData = questionsArray.find(
      (element, index) => index === questionIndex
    );
    // return last element if index question is not found
    if (!perPageData) {
      if (questionsArray.length) {
        finalPageNo = questionsArray.length;
        perPageData = questionsArray[questionsArray.length - 1];
      } else {
        finalPageNo = 1;
        perPageData = { ...formData, inputs: null };
      }
    }
  }
  return { pageNo: finalPageNo, pageData: perPageData, totalPages };
};

const checkPageValidity = ({ correctAnswer }) => {
  let validPage = false;
  if (
    (correctAnswer || {}).type === singleselectQuestion.type &&
    correctAnswer.value !== ""
  ) {
    validPage = true;
  } else if (
    (correctAnswer || {}).type === multiselectQuestion.type &&
    correctAnswer.value.length > 0
  ) {
    validPage = true;
  }
  return validPage;
};

const QuizCreateUI = ({
  createForm,
  addInput,
  addOption,
  deleteData,
  addItemInOption,
  addItemInInput,
  formJson,
  updateData,
  jsonLoading,
  correctAnswers,
  setcorrectAnswers,
  quizId,
  pageType,
}) => {
  const [pageNo, setPageNo] = useState(1);
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    text: "",
  });
  const currentFormObject = getFormPerPage({ pageNo, formData: formJson });
  const fields = getFormState({ formData: formJson });
  const [form] = Form.useForm();
  if (pageNo !== currentFormObject.pageNo) {
    setPageNo(currentFormObject.pageNo);
  }
  const totalQuestions = (formJson.inputs || []).length;
  const submitFormPageNo = currentFormObject.totalPages;
  let formElementIdGroups = null;
  if (!jsonLoading) {
    if (!(formJson || {}).id) {
      createForm();
    } else {
      formElementIdGroups = getIdsByElements({
        pageNo,
        currentFormData: currentFormObject.pageData,
        submitFormPageNo,
      });
    }
  }

  const checkAlerting = ({ correctAnswer }) => {
    if (checkPageValidity({ correctAnswer })) {
      setAlertInfo({
        show: false,
        text: "",
      });
    } else {
      setAlertInfo({
        show: true,
        text: "Please select an answer",
      });
    }
  };

  const markCorrectOption = ({ questionType, optionId, questionId }) => {
    let correctAnswer = null;
    if (!correctAnswers[questionId]) {
      correctAnswer = {
        [questionId]: {
          type: questionType,
          value: null,
        },
      };
    } else {
      correctAnswer = { [questionId]: { ...correctAnswers[questionId] } };
    }
    if (questionType === multiselectQuestion.type) {
      if (!correctAnswers[questionId].value) {
        correctAnswer[questionId].value = [];
      }
      correctAnswer[questionId].value.push(optionId);
    } else {
      correctAnswer[questionId].value = optionId;
    }
    checkAlerting({ correctAnswer: correctAnswer[questionId] });
    const finalCorrectAnswers = { ...correctAnswers, ...correctAnswer };
    setcorrectAnswers(finalCorrectAnswers);
  };

  const markIncorrectOption = ({ questionType, optionId, questionId }) => {
    const correctAnswer = {
      [questionId]: { ...correctAnswers[questionId] },
    };
    if (questionType === multiselectQuestion.type) {
      correctAnswer[questionId].value = correctAnswers[questionId].value.filter(
        (id) => id !== optionId
      );
    } else {
      correctAnswer[questionId].value = "";
    }
    const finalCorrectAnswers = { ...correctAnswers, ...correctAnswer };

    checkAlerting({ correctAnswer: correctAnswer[questionId] });
    setcorrectAnswers(finalCorrectAnswers);
  };

  const changeQuestionType = ({ type, questionId }) => {
    const correctAnswer = {
      [questionId]: { ...correctAnswers[questionId] },
    };
    if (!correctAnswers[questionId]) {
      correctAnswer[questionId] = {
        type,
      };
      if (type === singleselectQuestion) {
        correctAnswer[questionId].value = "";
      } else {
        correctAnswer[questionId].value = [];
      }
    } else if (
      type === singleselectQuestion.type &&
      correctAnswers[questionId].type !== singleselectQuestion.type
    ) {
      correctAnswer[questionId].type = type;
      correctAnswer[questionId].value = "";
    } else if (
      type === multiselectQuestion.type &&
      correctAnswers[questionId].type !== multiselectQuestion.type
    ) {
      correctAnswer[questionId].type = type;
      correctAnswer[questionId].value = correctAnswers[questionId].value
        ? [correctAnswers[questionId].value]
        : [];
    }
    setcorrectAnswers({ ...correctAnswers, ...correctAnswer });
  };

  const validateInput = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (e) {
      return false;
    }
  };

  const pageChange = async ({ type }) => {
    const formCheck = await validateInput();
    if (
      pageNo !== formMetadataPageNo &&
      pageNo !== submitFormPageNo &&
      !checkPageValidity({
        correctAnswer: correctAnswers[currentFormObject.pageData.id],
      })
    ) {
      setAlertInfo({
        show: true,
        text: "Please select an answer",
      });
    } else if (formCheck) {
      setAlertInfo({
        show: false,
        text: "",
      });
      if (type === "prev" && pageNo > 1) {
        setPageNo(pageNo - 1);
      } else if (pageNo < currentFormObject.totalPages) {
        setPageNo(pageNo + 1);
      }
    }
  };

  let headerText = `Title`;
  if (pageNo !== formMetadataPageNo && pageNo !== submitFormPageNo) {
    headerText = `Question ${pageNo - 1}/${currentFormObject.totalPages - 2}`;
  } else if (pageNo === submitFormPageNo) {
    headerText = `Submit`;
  }
  return (
    <>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 12, offset: 6 }}
          xl={{ span: 8, offset: 8 }}
        >
          <PageHeader
            title={`${pageType === "edit" ? "Edit" : "Create"} Question Paper`}
          />
          <Card>
            {jsonLoading ||
            ((!currentFormObject ||
              Object.keys(currentFormObject.pageData || {}).length === 0) &&
              pageNo !== submitFormPageNo) ? (
              <Skeleton />
            ) : (
              <>
                <Header text={headerText} />
                <QuizForm
                  type={pageType}
                  quizId={quizId}
                  fields={fields}
                  onChange={({ updatedFields }) => {
                    // use update functions in props and remove the use of state for fields
                    updateForm({
                      elementIdGroups: formElementIdGroups,
                      updatedFields,
                      updateData,
                    });
                    updatedFields.forEach((field) => {
                      const [inputId, inputType] = field.name[0].split(
                        separatorString
                      );
                      if (
                        (formElementIdGroups.input || []).includes(inputId) &&
                        inputType === "type"
                      ) {
                        changeQuestionType({
                          type: field.value,
                          questionId: inputId,
                        });
                      }
                    });
                  }}
                  pageNo={currentFormObject.pageNo}
                  formData={currentFormObject.pageData}
                  markCorrectOption={markCorrectOption}
                  markIncorrectOption={markIncorrectOption}
                  correctAnswers={correctAnswers}
                  addInput={addInput}
                  addItemInInput={addItemInInput}
                  addOption={addOption}
                  addItemInOption={addItemInOption}
                  deleteData={deleteData}
                  updateData={updateData}
                  submitFormPageNo={submitFormPageNo}
                  totalQuestions={totalQuestions}
                  jsonFormObject={formJson}
                  form={form}
                />
                <Divider />
                {alertInfo.show && (
                  <>
                    <Alert type="error" message={alertInfo.text} showIcon />
                    <Divider />
                  </>
                )}
                {pageNo !== 1 && (
                  <Button
                    icon={<ArrowLeftOutlined />}
                    onClick={() => {
                      pageChange({ type: "prev" });
                    }}
                  >
                    Previous
                  </Button>
                )}
                {pageNo !== currentFormObject.totalPages && (
                  <Button
                    className="nextButton"
                    onClick={() => {
                      pageChange({ type: "next" });
                    }}
                  >
                    Next
                    <ArrowRightOutlined />
                  </Button>
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default QuizCreateUI;
