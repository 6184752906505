import { useState, useEffect } from "react";

import { get } from "../utils/networkUtils";
import { assessment as assessmentUrl } from "../utils/apiDict";

const useAssessment = ({ assessmentId, userId, ref }) => {
  const [loading, setLoading] = useState(true);
  const [assessment, setAssessment] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (ref.current) {
      (async () => {
        const res = await get({
          url: assessmentUrl
            .replace(":aid", assessmentId)
            .replace(":uid", userId),
        });
        if (res.ok) {
          setAssessment(res.data);
        } else {
          setError(res.problem);
        }
        setLoading(false);
      })();
    }
    return () => {
      ref.current = false;
    };
  }, [assessmentId, userId, ref]);

  return { loading, assessment, error };
};

export default useAssessment;
