import React from "react";
import { Image, Typography } from "antd";
import { CloseCircleFilled, CheckCircleTwoTone } from "@ant-design/icons";

import "./ReportQuestion.css";
import ReportAnswer from "../ReportAnswer/ReportAnswer";
import { inputTypes, imageSize } from "../../../utils/constants";
import { serveImage } from "../../../utils/apiDict";
import { checkIfCorrectAnswer } from "../../../utils/utils";

const { Title, Text } = Typography;

const ReportQuestion = ({
  title,
  options,
  questionNo,
  totalQuestion,
  correctAnswer,
  submissionAnswer,
  maxScore,
  scoreObtained,
}) => {
  const questionText = [];
  const questionImages = [];
  title.items.forEach(({ type, value }) => {
    if (type === inputTypes.image) {
      questionImages.push(value);
    } else {
      questionText.push(value);
    }
  });
  const { correct, answerMap, correctMap } = checkIfCorrectAnswer({
    submittedAnswer: submissionAnswer,
    correctAnswer,
  });
  return (
    <>
      <div className="question-card">
        <span className="number-span q-number">
          <Text>
            Question {questionNo}/{totalQuestion}
          </Text>
        </span>
        {!correct && (
          <span className="q-type incorrect-q-type">
            <CloseCircleFilled className="ansresult" />
            <Text className="highligh-text">Incorrect</Text>
          </span>
        )}
        {correct && (
          <span className="q-type correct-q-type">
            <CheckCircleTwoTone className="ansresult" twoToneColor="#45d84c" />
            <Text className="highligh-text">Correct</Text>
          </span>
        )}
        <span className="number-span mark-number">
          <Text>
            Marks: {scoreObtained}/{maxScore}
          </Text>
        </span>
        <Title level={2} className="question-text">
          {questionText.join(" ")}
        </Title>
        {questionImages.length > 0 && (
          <ul className="thumbnail-images">
            <li>
              {questionImages.map((url) => {
                return (
                  <li key={url}>
                    <Image
                      className="thumbnail"
                      src={`${process.env.REACT_APP_API_SERVER}${serveImage}${url}/${imageSize}`}
                      fallback={`${process.env.PUBLIC_URL}/placeholder.png`}
                    />
                  </li>
                );
              })}
            </li>
          </ul>
        )}
        <ul className="answer-list">
          {options.map(({ items, id }) => {
            return (
              <ReportAnswer
                items={items}
                key={id}
                type={correctMap[id] ? "right" : "wrong"}
                selectedAnswer={answerMap[id]}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ReportQuestion;
