import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Spin } from "antd";
import React from "react";

const TapAndRecordCard = ({ isAnalysing }) => {
  if (isAnalysing) {
    const spinner = (
      <LoadingOutlined style={{ fontSize: "24px", color: "blue" }} spin />
    );
    return (
      <Row justify="center" className="w100 p24">
        <Spin indicator={spinner} />
      </Row>
    );
  }
  return (
    <>
      <Col
        md={{ offset: 5 }}
        lg={{ offset: 5 }}
        xl={{ offset: 5 }}
        style={{
          height: "44px",
          width: "44px",
          backgroundColor: "#eceaf9",
          borderRadius: "22px",
          padding: "10px 15px",
        }}
        className="mt10 ml16 cursorpointer"
      >
        <img src="/playquiz/mic.svg" alt="Record" />
      </Col>
      <Col style={{ padding: "22px 5px" }} offset={4}>
        <span style={{ color: "#8181cf", fontSize: "16px", fontWeight: "600" }}>
          Tap & Record
        </span>
      </Col>
    </>
  );
};

export default TapAndRecordCard;
