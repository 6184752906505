import { useState } from "react";

import { events } from "../utils/constants";
import { post } from "../utils/networkUtils";
import { assessmentSubmission } from "../utils/apiDict";

const useSubmission = ({ assessmentId, userId }) => {
  const [loading, setLoading] = useState(false);

  const submit = async ({ event = events.submitAnswer, answers }) => {
      setLoading(true);
      const response = await post({
        url: assessmentSubmission,
        body: {
          assessmentId,
          userId,
          event,
          answers,
        },
      });
      setLoading(false);

      if (
        !response.ok &&
        [events.submitAssessment, events.timeout].includes(event)
      ) {
        return false;
      }
      return true;
    };

  return {
    loading,
    submit,
  };
};

export default useSubmission;
