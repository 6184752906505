import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import Page from "../shared/Page";
import Error from "../shared/Error";
import Loading from "../shared/Loading";
import useAssessment from "../../hooks/useAssessment";
import useSubmission from "../../hooks/useSubmission";
import QuestionSet from "../shared/QuestionSet/QuestionSet";
import { post } from "../../utils/networkUtils";
import { assessmentEvent } from "../../utils/apiDict";
import { events } from "../../utils/constants";

const StudentAssessment = () => {
  const { aid: assessmentId, uid: userId } = useParams();
  const isMounted = useRef(true);
  const submission = useSubmission({
    assessmentId,
    userId,
  });

  const { loading, assessment, error } = useAssessment({
    assessmentId,
    userId,
    ref: isMounted,
  });

  // add reload warning
  useEffect(() => {
    if (assessment && assessment.data && assessment.data.allowed) {
      onbeforeunload = () => "";
    }
  }, [assessment]);

  useEffect(() => {
    post({
      url: assessmentEvent,
      body: { assessmentId, userId, event: events.linkOpen },
    });
  }, [assessmentId, userId]);

  if (loading) {
    return <Loading message="Preparing Assessment" />;
  }

  if (error || !assessment.ok) {
    return <Error error={error || assessment.err} />;
  }

  if (!assessment.data.allowed) {
    return <Page page={assessment.data} />;
  }

  return <QuestionSet exam={assessment} submission={submission} />;
};

export default StudentAssessment;
