import React from "react";
import { Form, Radio } from "antd";

import { indexOptionDict } from "../../../utils/constants";
import ReloadableImage from "../ReloadableImage";

import "../shared.css";

export default (field) => {
  return (
    <Form.Item name={[field.id, "value"]} initialValue={field.solution}>
      <Radio.Group disabled={field.preview}>
        {field.options.map((option, index) => (
          <Radio
            value={option.id}
            key={option.id}
            style={{ display: "block", padding: "10px", whiteSpace: "normal" }}
          >
            {option.items.map(({ id, type, value }) => {
              switch (type) {
                case "string":
                  return `${indexOptionDict[index]}. ${value}`;
                case "img":
                  return (
                    <ReloadableImage
                      className="displayImage"
                      value={value}
                      key={id}
                    />
                  );
                default:
                  return null;
              }
            })}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};
