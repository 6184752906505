import React, { useRef } from "react";
import { useParams } from "react-router-dom";

import Error from "../shared/Error";
import Loading from "../shared/Loading";
import useQuizDetails from "../../hooks/useQuizDetails";
import QuestionSet from "../shared/QuestionSet/QuestionSet";

const QuizPreview = () => {
  const { qid: quizId } = useParams();
  const ref = useRef(true);

  const { loading, quizDetails, error } = useQuizDetails({
    quizId,
    ref,
  });

  if (loading) {
    return <Loading message="Generating Preview" />;
  }

  if (error || !quizDetails.ok || !quizDetails.data) {
    return (
      <Error error={error || quizDetails.err || "Question paper not found"} />
    );
  }

  return <QuestionSet exam={quizDetails} />;
};

export default QuizPreview;
