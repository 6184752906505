import { useState, useEffect } from "react";

import { get } from "../utils/networkUtils";
import { assessmentReport as assessmentReportAPI } from "../utils/apiDict";

const useAssessmentSubmission = ({ userId, assessmentId, ref }) => {
  const [loading, setLoading] = useState(true);
  const [assessmentSubmissionDetails, setAssessmentSubmissionDetails] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (ref.current && userId && assessmentId) {
      (async () => {
        const res = await get({
          url: assessmentReportAPI.replace(":aid", assessmentId).replace(":uid", userId),
          config: { withCredentials: true },
        });

        if (res.ok) {
          setAssessmentSubmissionDetails(res.data);
        } else {
          setError(res.problem);
        }
        setLoading(false);
      })();
    }
    if (!assessmentId || !userId) {
      setLoading(false);
    }
    return () => {
      ref.current = false;
    };
  }, [assessmentId, ref, userId]);

  return { loading, assessmentSubmissionDetails, error };
};

export default useAssessmentSubmission;
