import React, { useRef } from "react";
import { Row, Col, Skeleton } from "antd";

import "./StudentReport.css";
import { useParams } from "react-router-dom";

import ReportQuestion from "../shared/ReportQuestion/ReportQuestion";
import useAssessmentSubmission from "../../hooks/useAssessmentReport";
import Error from "../shared/Error";

const StudentReport = () => {
  const { aid: assessmentId, uid: userId } = useParams();
  const ref = useRef(true);
  let error = "";
  const {
    loading,
    error: dataError,
    assessmentSubmissionDetails,
  } = useAssessmentSubmission({ assessmentId, userId, ref });
  if (!assessmentSubmissionDetails.ok) {
    error =
      assessmentSubmissionDetails.err || "Error occured. Please try later";
  }
  const {data} = assessmentSubmissionDetails;
  return (
    <>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 12, offset: 6 }}
          xl={{ span: 8, offset: 8 }}
        >
          {!loading && (error || dataError) && <Error error={error} />}
          {loading && <Skeleton />}
          {!loading && !error && !dataError && (
            <div className="report-container">
              <em className="bold-font assessmentTitle">Assessment Report</em>
              <div className="student-detail">
                <div className="common-box tmargin10">
                  <span className="det-label">Student Name</span>
                  <em className="thin-font">{data.studentName}</em>
                </div>
                <div className="common-box tmargin10">
                  <span className="det-label">Subject</span>
                  <em className="thin-font">{data.subject}</em>
                </div>
                <div className="score-box">
                  <div className="score-val">
                    <em className="thin-font">
                      <em>{data.scoreObtained}</em>/{data.maxScore}
                    </em>
                    <span className="det-label">Score</span>
                  </div>
                </div>
              </div>
              <div className="repeated-row">
                <div className="tmargin10">
                  <span className="det-label">Title</span>
                  <em className="thin-font">{data.title}</em>
                </div>
              </div>
              <div className="repeated-row">
                <div className="common-box tmargin10">
                  <span className="det-label">Test Date</span>
                  <em className="thin-font">{data.examDate}</em>
                </div>
                <div className="common-box tmargin10">
                  <span className="det-label">Test Duration</span>
                  <em className="thin-font">{data.duration} Minutes</em>
                </div>
              </div>
              {data.questionList.map(({ title, options, maxScore, scoreObtained, correctAnswer, submissionAnswer}, index) => {
                return <ReportQuestion key={`${maxScore}-${scoreObtained}-${title.items[0].id}`} title={title} options={options} questionNo={index + 1} totalQuestion={data.questionList.length} correctAnswer={correctAnswer} submissionAnswer={submissionAnswer} maxScore={maxScore} scoreObtained={scoreObtained}/>;
              })}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default StudentReport;
