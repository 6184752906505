import React, { useState, useEffect } from "react";
import { Button, Form, Divider, Alert } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Redirect } from "react-router-dom";

import useQuizEdit from "../../../hooks/useQuizEdit";
import { quiz as quizUrl } from "../../../utils/apiDict";
import { post } from "../../../utils/networkUtils";
import {
  quizListUrl,
  placeholderErrorResponse,
  singleselectQuestion,
  minQuestions,
} from "../../../utils/constants";
import QuizMetadataForm from "../QuizMetadataForm";
import RenderQuestion from "./RenderQuestions";
import RenderOption from "./RenderOption";

const formMetadataPageNo = 1;
const createRequestData = ({ formData, answers }) => {
  const quizData = {
    id: formData.id,
    title: formData.title.items[0].value,
    description: formData.description,
    duration: formData.duration,
    class: formData.class,
    subject: formData.subject,
    questions: formData.inputs,
    solution: {},
  };
  Object.keys(answers).forEach((answerKey) => {
    quizData.solution[answerKey] = answers[answerKey].value;
  });
  return quizData;
};

const saveQuiz = async ({
  data,
  redirection,
  setLoading,
  setError,
  setRedirect,
  setSubmitSuccess,
  answers,
}) => {
  setLoading(true);
  setError("");
  setSubmitSuccess("");
  try {
    // for converting title to just string
    const parsedData = createRequestData({ formData: data, answers });
    const createdQuiz = await post({
      url: quizUrl,
      body: parsedData,
      config: { withCredentials: true },
    });
    if (!((createdQuiz || {}).data || {}).ok) {
      throw ((createdQuiz || {}).data || {}).err;
    }
    setSubmitSuccess("Question set created");
    setTimeout(() => {
      if (redirection === "assessmentTestCreate") {
        const redirectUrl = `/assessment/create?aqid=${createdQuiz.data.data.id}`;
        setRedirect(redirectUrl);
      } else {
        window.location = `${process.env.REACT_APP_UOLO_WEB}${quizListUrl}`;
      }
    }, 3000);
  } catch (e) {
    setLoading(false);
    setError(e || placeholderErrorResponse);
  }
};

const QuizForm = ({
  onChange,
  fields,
  formData,
  pageNo,
  markCorrectOption,
  markIncorrectOption,
  correctAnswers,
  addInput,
  addItemInInput,
  addOption,
  addItemInOption,
  deleteData,
  submitFormPageNo,
  totalQuestions,
  jsonFormObject,
  form,
  quizId,
  type,
}) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const {
    error: editError,
    editQuiz,
    success: editSuccess,
  } = useQuizEdit({
    quizId,
  });
  useEffect(() => {
    let timeout = null;
    if (editSuccess) {
      if (saveLoading) {
        timeout = setTimeout(() => {
          window.location = `${process.env.REACT_APP_UOLO_WEB}${quizListUrl}`;
        }, 3000);
        setSaveLoading(false);
      } else if (submitLoading) {
        timeout = setTimeout(() => {
          setRedirectUrl(`/assessment/create?aqid=${quizId}`);
        }, 3000);
        setSubmitLoading(false);
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [editSuccess]);
  useEffect(() => {
    if (editError) {
      if (saveLoading) {
        setSaveLoading(false);
      } else if (submitLoading) {
        setSubmitLoading(false);
      }
    }
  }, [editError]);
  const QuestionButton = () => (
    <Button
      icon={<PlusOutlined />}
      type="primary"
      onClick={() => {
        addInput({
          data: { type: singleselectQuestion.type },
          indexLocation: pageNo - 1,
        });
      }}
    >
      Add Question
    </Button>
  );

  let totalAnswers = 0;
  if (![formMetadataPageNo, submitFormPageNo].includes(pageNo)) {
    totalAnswers = ((formData || {}).options || []).length;
  }
  return (
    <>
      {redirectUrl && <Redirect to={redirectUrl} />}
      {!redirectUrl && (
        <Form
          form={form}
          name="global_state"
          layout="vertical"
          onFieldsChange={(updatedFields, allFields) => {
            onChange({
              updatedFields,
              newFields: allFields,
            });
          }}
          fields={fields}
        >
          {pageNo === formMetadataPageNo && (
            <>
              <QuizMetadataForm formData={formData} />
              <Divider />
              <QuestionButton pageNo={pageNo} />
            </>
          )}
          {![formMetadataPageNo, submitFormPageNo].includes(pageNo) && (
            <>
              <RenderQuestion
                id={formData.id}
                items={formData.title.items}
                deleteData={deleteData}
                addItemInInput={addItemInInput}
                showRemoveQuestion={totalQuestions > minQuestions}
                onRemoveQuestion={() => {
                  deleteData({ id: formData.id, element: "input" });
                }}
              />
              <Divider className="quesAnsDivider" />
              {((formData || {}).options || []).map(({ id, items }, index) => (
                <RenderOption
                  id={id}
                  items={items}
                  label={`Answer ${index + 1}`}
                  key={id}
                  markCorrectOption={markCorrectOption}
                  markIncorrectOption={markIncorrectOption}
                  questionType={formData.type}
                  questionId={formData.id}
                  correctAnswer={correctAnswers[formData.id]}
                  deleteData={deleteData}
                  totalAnswers={totalAnswers}
                  addItemInOption={addItemInOption}
                />
              ))}
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => {
                  addOption({ inputId: formData.id });
                }}
                size="small"
              >
                Add Answer
              </Button>
              <Divider />
              <QuestionButton pageNo={pageNo} />{" "}
            </>
          )}
          {submitFormPageNo === pageNo && (
            <>
              <Button
                type="primary"
                disabled={submitLoading}
                loading={saveLoading}
                onClick={async () => {
                  if (type !== "edit") {
                    saveQuiz({
                      data: jsonFormObject,
                      redirection: "quizList",
                      setLoading: setSaveLoading,
                      setError: setSubmitError,
                      setSubmitSuccess,
                      answers: correctAnswers,
                      setRedirect: setRedirectUrl,
                    });
                  } else {
                    const requestData = createRequestData({
                      formData: jsonFormObject,
                      answers: correctAnswers,
                    });
                    setSaveLoading(true);
                    await editQuiz({ quizData: requestData });
                  }
                }}
              >
                Save
              </Button>{" "}
              <Button
                type="primary"
                disabled={saveLoading}
                loading={submitLoading}
                onClick={async () => {
                  if (type !== "edit") {
                    saveQuiz({
                      data: jsonFormObject,
                      redirection: "assessmentTestCreate",
                      setLoading: setSubmitLoading,
                      setError: setSubmitError,
                      setSubmitSuccess,
                      answers: correctAnswers,
                      setRedirect: setRedirectUrl,
                    });
                  } else {
                    const requestData = createRequestData({
                      formData: jsonFormObject,
                      answers: correctAnswers,
                    });
                    setSubmitLoading(true);
                    await editQuiz({ quizData: requestData });
                  }
                }}
              >
                Save and Schedule
              </Button>
              {(submitError || editError) && (
                <>
                  <br />
                  <br />
                  <Alert
                    type="error"
                    message={submitError || editError}
                    showIcon
                  />
                </>
              )}
              {(submitSuccess || editSuccess) && (
                <>
                  <br />
                  <br />
                  <Alert
                    type="success"
                    message={submitSuccess || "Question set edited"}
                    showIcon
                  />
                </>
              )}
            </>
          )}
        </Form>
      )}
    </>
  );
};

export default QuizForm;
