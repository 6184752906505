/* eslint react/prop-types: 0 */
// @todo anupam - add prop types
import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Radio,
  InputNumber,
  TimePicker,
  Row,
  Col,
} from "antd";
import { DateTime } from "luxon";

import { maxLengthTitle, maxLengthDescription } from "../../../utils/constants";
import { get } from "../../../utils/networkUtils";
import { group as groupApi } from "../../../utils/apiDict";

const { Option } = Select;

const getGroupData = async ({ setGroupData }) => {
  const groupApiResponse = await get({
    url: groupApi,
    config: { withCredentials: true },
  });
  if (!groupApiResponse.ok) {
    return;
  }
  setGroupData(groupApiResponse.data.data || []);
};

const AssessmentForm = ({ formData, onSubmitForm, loading }) => {
  const [form] = Form.useForm();
  const [groupData, setGroupData] = useState([]);
  useEffect(() => {
    getGroupData({ setGroupData });
  }, [true]);
  const onSubmit = (values) => {
    onSubmitForm(values);
  };
  return (
    <Form
      form={form}
      name="basic"
      initialValues={{
        title: formData.title,
        subject: formData.subject,
        notificationTime:
          formData.notificationTime &&
          formData.startTime &&
          DateTime.fromISO(formData.startTime)
            .diff(DateTime.fromISO(formData.notificationTime))
            .as("minutes"),
        description: formData.description,
        groupIds: (formData.groups || []).map((group) => group.id),
        startDate: formData.startTime && DateTime.fromISO(formData.startTime),
        startTime: formData.startTime && DateTime.fromISO(formData.startTime),
        duration:
          formData.endTime &&
          formData.startTime &&
          DateTime.fromISO(formData.endTime)
            .diff(DateTime.fromISO(formData.startTime))
            .as("minutes"),
      }}
      onFinish={onSubmit}
      layout="vertical"
    >
      <Form.Item
        label="Title"
        name="title"
        rules={[
          {
            required: true,
            message: "Please give a title",
          },
        ]}
      >
        <Input maxLength={maxLengthTitle} />
      </Form.Item>
      <Form.Item label="Subject" name="subject">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input.TextArea maxLength={maxLengthDescription} />
      </Form.Item>
      <Form.Item
        label="Groups"
        name="groupIds"
        rules={[
          {
            required: true,
            message: "Please select a group",
          },
        ]}
      >
        <Select mode="multiple">
          {/** todo: get groups from API */}
          {(groupData || []).map((group) => (
            <Option key={group.id} value={group.id}>
              {group.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Assessment Date"
            name="startDate"
            rules={[
              {
                required: true,
                message: "Please select Assessment Date",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              disabledDate={(date) => {
                return (
                  date && date.startOf("day") < DateTime.now().startOf("day")
                );
              }}
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Assessment Time"
            name="startTime"
            rules={[
              {
                required: true,
                message: "Please select Assessment time",
              },
              ({ getFieldValue }) => ({
                validator: async (rule, value) => {
                  const selectedDate = getFieldValue("startDate");
                  if (
                    selectedDate &&
                    value &&
                    DateTime.fromFormat(
                      `${selectedDate.format("DD-MM-YYYY")} ${value.format(
                        "HH:mm"
                      )}`,
                      "dd-MM-yyyy HH:mm"
                    ) <= DateTime.now()
                  ) {
                    throw new Error("Please select a time after current time");
                  }
                  return true;
                },
              }),
            ]}
          >
            <TimePicker style={{ width: "100%" }} format="h:mm a" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Duration (in minutes)"
        name="duration"
        rules={[
          {
            required: true,
            message: "Please specify assessment duration",
          },
        ]}
      >
        <InputNumber
          style={{ width: "100%" }}
          size="default"
          min={1}
          max={8000}
        />
      </Form.Item>
      <Form.Item
        label="Notify Before"
        name="notificationTime"
        rules={[
          {
            required: true,
            message: "Please select when to notify",
          },
        ]}
      >
        <Radio.Group>
          <Radio value={120}>2 hours</Radio>
          <Radio value={60}>1 hour</Radio>
          <Radio value={30}>30 minutes</Radio>
          <Radio value={15}>15 minutes</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AssessmentForm;
