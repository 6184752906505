import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";

import QuizCreateUI from "./QuizCreateUI";
import { inputTypes, singleselectQuestion } from "../../utils/constants";
import useQuizDetails from "../../hooks/useQuizDetails";
import Error from "../shared/Error";

const defaultscore = 1;

const getJsonFromQuizData = ({ quizData }) => {
  const formId = uuidv4();
  const titleItemId = uuidv4();
  const correctAnswers = {};
  const mapOfIds = {
    [titleItemId]: formId,
  };
  const quizCreateObj = {
    id: formId,
    v: 1,
    type: "form",
    title: {
      items: [
        {
          id: titleItemId,
          type: inputTypes.string,
          value: quizData.title,
        },
      ],
    },
    description: quizData.description,
    duration: quizData.duration,
    subject: quizData.subject,
    class: quizData.class,
  };
  quizCreateObj.inputs = quizData.questions.map(
    ({ id: inputId, type: inputType, score, title, options }) => {
      const inputObj = {
        id: inputId,
        type: inputType,
        score,
        title: {
          items: [],
        },
        options: [],
      };
      correctAnswers[inputId] = {
        type: inputType,
        value: quizData.solution[inputId],
      };
      inputObj.title.items = title.items.map(({ id, type, value }) => {
        mapOfIds[id] = inputId;
        return {
          id,
          type,
          value,
        };
      });
      inputObj.options = options;
      options.forEach(({ id: optionId, items }) => {
        mapOfIds[optionId] = inputId;
        items.forEach(({ id }) => {
          mapOfIds[id] = optionId;
        });
      });
      return inputObj;
    }
  );
  return { mapOfIds, quizCreateObj, correctAnswers };
};

const QuizCreate = () => {
  const ref = useRef(true);
  const [mapOfIds, setMapOdIds] = useState({});
  const [loading, setLoading] = useState(true);
  const [correctAnswers, setcorrectAnswers] = useState({});
  const { qid, type } = useParams();
  const quizDetails = useQuizDetails({ quizId: qid, ref });

  const createForm = () => {
    const newMapOfIds = { ...mapOfIds };

    const uuid = uuidv4();
    const titleItemId = uuidv4();
    const uuidInput = uuidv4();
    const inputTitleItemId = uuidv4();
    const optionId = uuidv4();
    const optionItemId = uuidv4();
    const optionId2 = uuidv4();
    const optionItemId2 = uuidv4();
    newMapOfIds[inputTitleItemId] = uuidInput;
    newMapOfIds[optionId] = uuidInput;
    newMapOfIds[optionItemId] = optionId;
    newMapOfIds[optionId2] = uuidInput;
    newMapOfIds[optionItemId2] = optionId2;
    newMapOfIds[titleItemId] = uuid;
    setMapOdIds(newMapOfIds);

    const jsonData = {
      type: "form",
      v: 1,
      id: uuid,
      title: {
        items: [
          {
            id: titleItemId,
            type: inputTypes.string,
            value: "",
          },
        ],
      },
      description: "",
      duration: 1,
      class: 1,
      subject: "",
      inputs: [
        {
          id: uuidInput,
          type: singleselectQuestion.type,
          score: defaultscore,
          title: {
            items: [
              {
                id: inputTitleItemId,
                type: inputTypes.string,
                value: "",
              },
            ],
          },
          options: [
            {
              id: optionId,
              items: [
                {
                  id: optionItemId,
                  type: inputTypes.string,
                  value: "",
                },
              ],
            },
            {
              id: optionId2,
              items: [
                {
                  id: optionItemId2,
                  type: inputTypes.string,
                  value: "",
                },
              ],
            },
          ],
        },
      ],
    };
    return jsonData;
  };

  const [json, setJson] = useState(createForm);
  useEffect(() => {
    if (quizDetails.quizDetails.ok) {
      const {
        mapOfIds: idMap,
        quizCreateObj,
        correctAnswers: answers,
      } = getJsonFromQuizData({
        quizData: quizDetails.quizDetails.data,
      });
      setMapOdIds(idMap);
      setJson(quizCreateObj);
      setcorrectAnswers(answers);
      setLoading(false);
    } else if (!qid) {
      setLoading(false);
    }
  }, [quizDetails.quizDetails]);

  if (type && !["clone", "edit"].includes(type)) {
    window.location = process.env.REACT_APP_UOLO_WEB;
    return null;
  }

  const addItemInFormTitle = ({ data }) => {
    const uuid = uuidv4();
    const item = { ...data, id: uuid };
    const newJson = { ...json };

    newJson.title.items.push(item);
    setJson(newJson);
    return item;
  };

  const addInput = ({ data, indexLocation }) => {
    const newMapOfIds = { ...mapOfIds };
    const uuid = uuidv4();
    const titleItemId = uuidv4();
    const optionId = uuidv4();
    const optionItemId = uuidv4();
    const optionId2 = uuidv4();
    const optionItemId2 = uuidv4();
    newMapOfIds[titleItemId] = uuid;
    newMapOfIds[optionId] = uuid;
    newMapOfIds[optionItemId] = optionId;
    newMapOfIds[optionId2] = uuid;
    newMapOfIds[optionItemId2] = optionId2;
    setMapOdIds(newMapOfIds);
    const inputData = {
      id: uuid,
      type: data.type,
      score: defaultscore,
      title: {
        items: [
          {
            id: titleItemId,
            type: inputTypes.string,
            value: "",
          },
        ],
      },
      options: [
        {
          id: optionId,
          items: [
            {
              id: optionItemId,
              type: inputTypes.string,
              value: "",
            },
          ],
        },
        {
          id: optionId2,
          items: [
            {
              id: optionItemId2,
              type: inputTypes.string,
              value: "",
            },
          ],
        },
      ],
    };
    const newInputArray = [...json.inputs];
    if (indexLocation !== null && indexLocation >= 0) {
      newInputArray.splice(indexLocation, 0, inputData);
    }

    setJson({ ...json, inputs: newInputArray });
    return inputData;
  };

  const addItemInInputTitle = ({ inputId, data }) => {
    const uuid = uuidv4();
    const newMapOfIds = { ...mapOfIds };
    newMapOfIds[uuid] = inputId;
    setMapOdIds(newMapOfIds);

    const item = { ...data, id: uuid };
    const newJson = { ...json };
    newJson.inputs.forEach((input) => {
      if (input.id === inputId) {
        input.title.items.push(item);
      }
    });
    setJson(newJson);
    return item;
  };

  const addOption = ({ inputId }) => {
    const uuid = uuidv4();
    const uuidItem = uuidv4();
    const newMapOfIds = { ...mapOfIds };
    newMapOfIds[uuid] = inputId;
    newMapOfIds[uuidItem] = uuid;
    setMapOdIds(newMapOfIds);

    const option = {
      id: uuid,
      items: [
        {
          id: uuidItem,
          type: inputTypes.string,
          value: "",
        },
      ],
    };

    const newJson = { ...json };
    newJson.inputs.forEach((item) => {
      if (inputId === item.id) item.options.push(option);
    });
    setJson(newJson);
    return option;
  };

  const addItemInOption = ({ optionId, data }) => {
    const inputId = mapOfIds[optionId];

    const uuid = uuidv4();
    const newMapOfIds = { ...mapOfIds };
    newMapOfIds[uuid] = optionId;
    setMapOdIds(newMapOfIds);

    const item = { ...data, id: uuid };
    const newJson = { ...json };
    newJson.inputs.forEach((input) => {
      if (input.id === inputId) {
        input.options.forEach((option) => {
          if (option.id === optionId) {
            option.items.push(item);
          }
        });
      }
    });

    setJson(newJson);
    return item;
  };

  const deleteInput = ({ inputId }) => {
    const newInputs = json.inputs.filter((input) => input.id !== inputId);
    setJson({ ...json, inputs: newInputs });

    return newInputs;
  };

  const deleteOption = ({ optionId }) => {
    const inputId = mapOfIds[optionId];
    const newMapOfIds = { ...mapOfIds };
    delete newMapOfIds[optionId];
    setMapOdIds(newMapOfIds);

    const newOptions = [];
    json.inputs.forEach((input) => {
      if (input.id === inputId) {
        input.options.forEach((option) => {
          if (option.id !== optionId) {
            newOptions.push(option);
          }
        });
      }
    });
    const newJson = { ...json };
    newJson.inputs.forEach((input) => {
      if (input.id === inputId) {
        input.options = newOptions;
      }
    });
    setJson(newJson);
    return newOptions;
  };

  const deleteFormItem = ({ itemId }) => {
    return;
  };

  const deleteInputItem = ({ itemId }) => {
    const inputId = mapOfIds[itemId];
    const newJson = { ...json };
    newJson.inputs = newJson.inputs.map((input) => {
      if (input.id === inputId) {
        const newInput = { ...input };
        const newInputTitleItems = [];
        input.title.items.forEach((titleItem) => {
          if (titleItem.id !== itemId) {
            newInputTitleItems.push(titleItem);
          }
        });
        newInput.title.items = newInputTitleItems;
        return newInput;
      }
      return input;
    });
    setJson(newJson);
  };

  const deleteOptionItem = ({ itemId }) => {
    const optionId = mapOfIds[itemId];
    const inputId = mapOfIds[optionId];
    let newOptions;
    const newJson = { ...json };

    newJson.inputs.forEach((input) => {
      if (input.id === inputId) {
        input.options.forEach((option) => {
          if (option.id === optionId) {
            option.items = option.items.filter((item) => item.id !== itemId);
          }
        });
      }
    });

    setJson(newJson);
    return newOptions;
  };

  const deleteItem = ({ id, itemElement }) => {
    switch (itemElement) {
      case "form":
        return deleteFormItem({ itemId: id });
      case "input":
        return deleteInputItem({ itemId: id });
      case "option":
        return deleteOptionItem({ itemId: id });
      default:
        break;
    }
  };

  const deleteData = ({ id, element, itemElement }) => {
    switch (element) {
      case "input":
        return deleteInput({ inputId: id });
      case "option":
        return deleteOption({ optionId: id });
      case "item":
        return deleteItem({ id, itemElement });
      default:
        break;
    }
  };

  const updateForm = ({ data }) => {
    const newJson = { ...json };
    newJson.type = data.type;
    setJson(newJson);
  };

  const updateFormTitle = ({ itemId, data }) => {
    const newJson = { ...json };
    let newItem;
    newJson.title.items.forEach((item) => {
      if (item.id === itemId) {
        item.type = data.type;
        item.value = data.value;

        newItem = item;
      }
    });
    setJson(newJson);
    return newItem;
  };

  const updateInput = ({ id: inputId, data }) => {
    let newInput = {};
    const newJson = { ...json };

    newJson.inputs = newJson.inputs.map((input) => {
      if (input.id === inputId) {
        newInput = { ...input };
        newInput.type = data.type || input.type;
        newInput.score = data.score || input.score;
        return newInput;
      }
      return input;
    });
    setJson(newJson);
    return newInput;
  };

  const updateInputTitle = ({ itemId, data }) => {
    const inputId = mapOfIds[itemId];

    const newJson = { ...json };
    let newItem;

    newJson.inputs.forEach((input) => {
      if (input.id === inputId) {
        input.title.items.forEach((item) => {
          if (item.id === itemId) {
            item.type = data.type;
            item.value = data.value;
            newItem = item;
          }
        });
      }
    });
    setJson(newJson);
    return newItem;
  };

  const updateOptionItem = ({ id: itemId, data }) => {
    const optionId = mapOfIds[itemId];
    const inputId = mapOfIds[optionId];
    let newItem;
    const newJson = { ...json };

    newJson.inputs.forEach((input) => {
      if (input.id === inputId) {
        input.options.forEach((option) => {
          option.items.forEach((item) => {
            if (item.id === itemId) {
              item.type = data.type;
              item.value = data.value;

              newItem = item;
            }
          });
        });
      }
    });
    setJson(newJson);
    return newItem;
  };

  const updateTitle = ({ id: itemId, titleElement, data }) => {
    switch (titleElement) {
      case "form":
        return updateFormTitle({ itemId, data });
      case "input":
        return updateInputTitle({ itemId, data });
      default:
        break;
    }
  };

  const updateFormFields = ({ fieldName, data }) => {
    const newJson = { ...json };
    let parsedValue = data.value;
    if (fieldName === "duration") {
      parsedValue = Number(parsedValue);
    }
    newJson[fieldName] = parsedValue;
    setJson(newJson);
  };

  const updateData = ({ id, element, titleElement, data }) => {
    switch (element) {
      case "form":
        return updateForm({ data });
      case "input":
        return updateInput({ id, data });
      case "option":
        return updateOptionItem({ id, data });
      case "title":
        return updateTitle({ id, titleElement, data });
      case "description":
      case "subject":
      case "class":
      case "duration":
        return updateFormFields({ fieldName: element, data });
      default:
        break;
    }
  };
  return (
    <div>
      {quizDetails.error && qid && <Error error={quizDetails.error} />}
      {!(qid && quizDetails.error) && (
        <QuizCreateUI
          createForm={createForm}
          addInput={addInput}
          addOption={addOption}
          deleteData={deleteData}
          addItemInOption={addItemInOption}
          addItemInForm={addItemInFormTitle}
          addItemInInput={addItemInInputTitle}
          updateData={updateData}
          formJson={json}
          jsonLoading={loading}
          correctAnswers={correctAnswers}
          setcorrectAnswers={setcorrectAnswers}
          pageType={type}
          quizId={qid}
        />
      )}
    </div>
  );
};

export default QuizCreate;
