import React, { useEffect } from "react";
import { useLocation, Switch, Route } from "react-router-dom";
import { useCookies } from "react-cookie";

import StudentAssessment from "./components/StudentAssessment/StudentAssessment";
import QuizCreate from "./components/QuizCreate/QuizCreate";
import QuizPreview from "./components/QuizPreview/QuizPreview";
import AssessmentCreate from "./components/AssessmentCreate/AssessmentCreate";
import AssessmentPreviewAndUpdate from "./components/AssessmentPreviewAndUpdate/AssessmentPreviewAndUpdate";
import StudentReport from "./components/StudentReport/StudentReport";
import DemoQuiz from "./components/DemoQuiz/DemoQuiz";

const App = () => {
  const location = useLocation();
  const [, setCookie] = useCookies();
  useEffect(() => {
    const uaid = new URLSearchParams(location.search).get("uaid");
    if (uaid) {
      setCookie(process.env.REACT_APP_COOKIE_NAME, uaid, {
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        maxAge: process.env.REACT_APP_COOKIE_EXPIRY_SECONDS,
        sameSite: "lax",
      });
    }
  }, [true]);

  return (
    <Switch>
      <Route path="/assessment/:aid/:uid/report">
        <StudentReport />
      </Route>
      <Route path="/assessment/:aid/:uid">
        <StudentAssessment />
      </Route>
      <Route path="/assessment/create">
        <AssessmentCreate />
      </Route>
      <Route path="/assessment/:aid">
        <AssessmentPreviewAndUpdate />
      </Route>
      <Route path="/quiz/create">
        <QuizCreate />
      </Route>
      <Route path="/quiz/:qid/preview">
        <QuizPreview />
      </Route>
      <Route path="/quiz/:qid/:type">
        <QuizCreate />
      </Route>
      <Route
        path="/demo-quiz/:leadId"
        // Demo for Quiz Module
      >
        <DemoQuiz />
      </Route>
      <Route
        component={() => {
          window.location.href = process.env.REACT_APP_UOLO_WEB;
          return null;
        }}
      />
    </Switch>
  );
};

export default App;
