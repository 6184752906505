export const quiz = "/v1/uolo/assessment/quiz";
export const assessment = "/v1/uolo/assessment/:aid/:uid";
export const imageUpload = "/media/upload/image";
export const serveImage = "/media/images/";
export const group = "/v1/uolo/group";
export const assessmentSubmission = "/v1/uolo/assessment/submission";
export const quizApi = "/v1/uolo/assessment/quiz/:quizId";
export const assessmentReport = "/v1/uolo/assessment/:aid/student/:uid/report";
export const publishStudentsAssessmentReport =
  "/v1/uolo/assessment/:aId/report/publish";
export const assessmentEvent = "/v2/uolo/assessment/event";
export const loadQuizWithLeadId = "/v1/quiz/web-demo/start?leadId="
export const analyzeAudio = "/v1/quiz/web-demo/audio/analysis"
