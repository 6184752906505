import React from "react";

import Radio from "./Radio";
import Checkbox from "./Checkbox";

const FieldInput = ({ type, ...rest }) => {
  switch (type) {
    case "radio":
      return <Radio {...rest} />;
    case "checkbox":
      return <Checkbox {...rest} />;
    default:
      return null;
  }
};

export default FieldInput;
