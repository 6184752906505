import { DateTime } from "luxon";
import React, { useState } from "react";
import { Typography, Button, Statistic } from "antd";
import { PlaySquareFilled } from "@ant-design/icons";

import "./shared.css";

const { Title, Paragraph, Text } = Typography;

const Page = ({ page, type, next }) => {
  const [startButton, setStartbutton] = useState(false);
  if (type === "start") {
    return (
      <div className="content">
        <Paragraph level={3}>{page.description}</Paragraph>
        <Text>Subject: {page.subject}</Text>
        <Text>Total Questions: {page.questionsCount}</Text>
        <Text>Total Marks: {page.maxScore}</Text>
        <br />
        <Button
          icon={<PlaySquareFilled />}
          type="primary"
          size="large"
          onClick={next}
        >
          Start
        </Button>
      </div>
    );
  }
  return (
    <div className="main">
      <div className="content">
        <Title level={3}>{page.msg}</Title>
        {!startButton && page.timeLeft && (
          <Statistic.Countdown
            style={{ fontSize: 20 }}
            value={DateTime.now().plus({ milliseconds: page.timeLeft })}
            onFinish={() => setStartbutton(true)}
          />
        )}
        {startButton && (
          <Button
            type="primary"
            size="large"
            onClick={() => window.location.reload(false)}
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default Page;
