import { Col, Row } from "antd";
import React from "react";

const RecordingCard = ({ timer }) => {
  let seconds = timer % 60;
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  let minute;
  if (timer < 60) {
    minute = "00";
  } else {
    minute = Math.floor(timer / 60);
  }
  return (
    <>
      <Col
        style={{
          height: "44px",
          width: "44px",
          backgroundColor: "#eceaf9",
          borderRadius: "22px",
          padding: "10px 15px",
        }}
        className="mt10 ml16"
      >
        <img src="/playquiz/mic.svg" alt="Listen" color="#ff0000" />
      </Col>
      <Col
        style={{ padding: "12px 5px" }}
        xs={{ offset: 1, span: 11 }}
        sm={{ offset: 1, span: 11 }}
        md={{ offset: 1, span: 10 }}
        lg={{ offset: 1, span: 8 }}
        xl={{ offset: 1, span: 8 }}
      >
        <Row>
          <span
            style={{ color: "#e80000", fontSize: "16px", fontWeight: "600" }}
          >
            Listening
          </span>
        </Row>
        <Row>
          <span
            style={{ color: "#818181", fontSize: "13px", fontWeight: "600" }}
          >
            Tap to stop recording
          </span>
        </Row>
      </Col>
      <Col
        style={{ paddingTop: "19px" }}
        xs={{ offset: 0 }}
        sm={{ offset: 2 }}
        md={{ offset: 3 }}
        lg={{ offset: 6 }}
        xl={{ offset: 7 }}
      >
        <span style={{ color: "#8181cf", fontSize: "20px", fontWeight: "800" }}>
          {minute}:{seconds}
        </span>
      </Col>
    </>
  );
};

export default RecordingCard;
