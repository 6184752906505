import React from "react";
import { Button, Typography, Divider } from "antd";
import { MinusOutlined } from "@ant-design/icons";

import MarkAnswerButton from "./MarkAnswerButton";
import RenderInput from "../RenderInput";
import { minAnswers } from "../../../utils/constants";

const { Text } = Typography;

const RenderOption = ({
  label,
  items,
  questionType,
  questionId,
  correctAnswer,
  id,
  markCorrectOption,
  markIncorrectOption,
  deleteData,
  totalAnswers,
  addItemInOption,
}) => {
  return (
    <>
      <div className="inputHeader">
        <Text>{label}</Text>{" "}
        {totalAnswers > minAnswers && (
          <Button
            className="removeAnsButton"
            danger
            type="primary"
            size="small"
            icon={<MinusOutlined />}
            onClick={() => {
              deleteData({ id, element: "option" });
              markIncorrectOption({ questionType, questionId, optionId: id })
            }}
          >
            Remove
          </Button>
        )}{" "}
        <MarkAnswerButton
          questionType={questionType}
          questionId={questionId}
          correctAnswer={correctAnswer}
          markCorrectOption={markCorrectOption}
          markIncorrectOption={markIncorrectOption}
          optionId={id}
        />
      </div>
      <RenderInput
        inputId={id}
        items={items}
        itemElement="option"
        deleteData={deleteData}
        renderImageUpload
        addItemInInput={addItemInOption}
      />
      <Divider />
    </>
  );
};

export default RenderOption;
