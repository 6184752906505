import React from "react";
import { Button, Row, Col, Select, Form, Typography, InputNumber } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import RenderInput from "../RenderInput";
import {
  multiselectQuestion,
  singleselectQuestion,
} from "../../../utils/constants";

const { Option } = Select;
const { Text } = Typography;

const separatorString = "##";

const questionOptions = [
  { name: multiselectQuestion.name, value: multiselectQuestion.type },
  { name: singleselectQuestion.name, value: singleselectQuestion.type },
];

const RenderQuestion = ({
  id,
  items,
  deleteData,
  addItemInInput,
  showRemoveQuestion,
  onRemoveQuestion,
}) => {
  return (
    <React.Fragment key={id}>
      <Text className="titleInput">Question Title</Text>{" "}
      {showRemoveQuestion && (
        <Button
          className="removeQuestion"
          icon={<MinusOutlined />}
          danger
          type="primary"
          onClick={onRemoveQuestion}
          size="small"
        >
          Remove Question
        </Button>
      )}
      <RenderInput
        inputId={id}
        items={items}
        deleteData={deleteData}
        itemElement="input"
        renderImageUpload
        addItemInInput={addItemInInput}
      />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={`${id}${separatorString}type`}
            label={<Text>Question Type</Text>}
          >
            <Select>
              {questionOptions.map(({ name, value }) => (
                <Option key={name} value={value}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[`${id}${separatorString}score`]}
            label="Question score"
            rules={[
              {
                required: true,
                message: "Please enter question score",
              },
            ]}
            required={false}
          >
            <InputNumber
              style={{ width: "100%" }}
              size="default"
              min={1}
              max={1000}
            />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RenderQuestion;
