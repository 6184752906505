import { DateTime } from "luxon";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Row,
  Col,
  Card,
  PageHeader,
  Statistic,
  BackTop,
  Button,
  message,
  Popconfirm,
  Typography,
} from "antd";
import { CopyOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import Question from "../Question";
import Page from "../Page";
import {
  fiveMinutesInMillis,
  countdownRedColor,
  quizListUrl,
  events,
} from "../../../utils/constants";
import { del } from "../../../utils/networkUtils";
import { quizApi } from "../../../utils/apiDict";

const { Text } = Typography;
const startScreen = 0;

const getSubmissionCount = ({ answers }) => {
  let count = 0;
  Object.keys(answers).forEach((answer) => {
    if (answers[answer] && answers[answer].length) {
      count += 1;
    }
  });
  return count;
};

const deleteQuestionPaper = async ({ qId, setDeleting }) => {
  setDeleting(true);
  const response = await del({
    url: quizApi.replace(":quizId", qId),
  });
  if (!response.ok || !(response.data || {}).ok) {
    setDeleting(false);
    return message.error("Unable to delete Question paper. Please try later");
  }
  message.success("Question Paper Deleted");
  return setTimeout(() => {
    window.location = `${process.env.REACT_APP_UOLO_WEB}${quizListUrl}`;
  }, 3000);
};

const QuestionSet = ({ exam, submission }) => {
  const [countStyle, setCountStyle] = useState({});
  const [endTime, setEndTime] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [screen, setScreen] = useState(startScreen);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    text: "",
    okText: "Submit",
  });
  const [event, setEvent] = useState();
  const [answers, setAnswers] = useState({});

  const totalQuestion = exam.data.questionsCount;
  const solution = submission ? answers : exam.data.solution || {};
  const question = screen ? exam.data.questions[screen - 1] : {};

  const modalOnOk = async () => {
    const response = await submission.submit({ answers, event });
    if (!response) {
      setModalContent({
        text: "We were unable to complete your submission! Please try again",
        okText: "Retry",
      });
    } else {
      onbeforeunload = () => null; // unset when doing final submission
      setModalVisible(false);
      window.location.reload(false);
    }
  };

  useEffect(() => {
    if (
      exam &&
      exam.data &&
      exam.data.answers &&
      Object.keys(exam.data.answers).length
    ) {
      setAnswers(exam.data.answers);
    }
  }, [exam]);

  useEffect(() => {
    setEndTime(DateTime.now().plus({ milliseconds: exam.data.timeLeft }));
    if (exam.data.timeLeft && exam.data.timeLeft < fiveMinutesInMillis) {
      setCountStyle({ color: countdownRedColor });
    } else {
      setTimeout(() => {
        setCountStyle({ color: countdownRedColor });
      }, exam.data.timeLeft - fiveMinutesInMillis);
    }
  }, [exam.data.timeLeft]);

  useEffect(() => {
    if (event === events.submitAssessment) {
      setModalContent({
        text: `You have answered ${getSubmissionCount({
          answers,
        })}/${totalQuestion} questions. Are you sure you want to submit?`,
        okText: "Submit",
      });
    } else if (event === events.timeout) {
      setModalContent({
        text: "Thank you! Your time is over.",
        okText: "Submit",
      });
    }
  }, [event, answers, totalQuestion]);

  const goNext = () => {
    setScreen(screen < totalQuestion ? screen + 1 : screen);
  };

  const goPrev = () => {
    setScreen(screen - 1);
  };

  const start = async () => {
    if (submission) {
      submission.submit({ answers, event: events.start });
    }
    goNext();
  };

  return (
    <Row>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 16, offset: 4 }}
        lg={{ span: 12, offset: 6 }}
        xl={{ span: 8, offset: 8 }}
      >
        <PageHeader
          title={exam.data.title}
          extra={
            !submission
              ? [
                  exam.data.canEdit && (
                    <Link key="editquiz" to={`/quiz/${exam.data.id}/edit`}>
                      <Button type="primary" icon={<EditOutlined />}>
                        Edit
                      </Button>
                    </Link>
                  ),
                  exam.data.canClone && (
                    <Link key="clonequiz" to={`/quiz/${exam.data.id}/clone`}>
                      <Button type="primary" icon={<CopyOutlined />}>
                        Clone
                      </Button>
                    </Link>
                  ),
                  exam.data.canDelete && (
                    <Popconfirm
                      key="deletepopup"
                      title="Are you sure you want to delete?"
                      okText="Delete"
                      okType="danger"
                      onConfirm={() =>
                        deleteQuestionPaper({
                          qId: exam.data.id,
                          setDeleting,
                        })
                      }
                      cancelText="Cancel"
                    >
                      <Button
                        type="danger"
                        icon={<DeleteOutlined />}
                        loading={deleting}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  ),
                ]
              : exam.data.timeLeft &&
                endTime && (
                  <Statistic.Countdown
                    value={endTime}
                    valueStyle={{ fontSize: "20px", ...countStyle }}
                    onFinish={async () => {
                      submission.submit({ answers });
                      setEvent(events.timeout);
                      setModalVisible(true);
                    }}
                  />
                )
          }
        >
              <Text>
                Taking the Assessment as{" "}
                <Text strong>
                  {exam.data.name} - {exam.data.groupName}
                </Text>
              </Text>
              </PageHeader>
        {submission && (
          <Modal
            visible={modalVisible}
            confirmLoading={submission.loading}
            onCancel={() => setModalVisible(false)}
            onOk={modalOnOk}
            okText={modalContent.okText}
            closable={false}
            maskClosable={false}
          >
            {modalContent.text}
          </Modal>
        )}
        <Card>
          {screen === startScreen && (
            <Page type="start" page={exam.data} next={start} />
          )}
          {screen !== startScreen && (
            <Question
              index={screen}
              total={totalQuestion}
              field={question}
              solution={solution[question.id]}
              save={async (answer) => {
                if (submission) {
                  const updatedAnswers = { ...answers, ...answer };
                  setAnswers(updatedAnswers);
                  submission.submit({ answers: updatedAnswers });
                  if (totalQuestion === screen) {
                    setEvent(events.submitAssessment);
                    setModalVisible(true);
                  }
                }
              }}
              next={goNext}
              prev={goPrev}
              preview={!submission}
            />
          )}
          <BackTop style={{ left: "50%" }} />
        </Card>
      </Col>
    </Row>
  );
};

export default QuestionSet;
