export const checkNaturalNo = ({ value }) =>
  Boolean((value || "").match(/^(?!0+$)\d+$/));

export const checkIfCorrectAnswer = ({ submittedAnswer, correctAnswer }) => {
  const result = { correct: false, answerMap: {}, correctMap: {} };
  const correctMap = {};
  if (Array.isArray(correctAnswer)) {
    correctAnswer.forEach((val) => {
      correctMap[val] = true;
    });
    result.correctMap = { ...correctMap };

    result.correct =
      (submittedAnswer || []).every((val) => {
        result.answerMap[val] = true;
        if (correctMap[val]) {
          correctMap[val] = false;
          return true;
        }
        return false;
      }) && (submittedAnswer || []).length === correctAnswer.length;
  } else {
    if (submittedAnswer) {
      result.answerMap[submittedAnswer] = true;
    }
    result.correctMap[correctAnswer] = true;
    if (submittedAnswer === correctAnswer) {
      result.correct = true;
    }
  }
  return result;
};
