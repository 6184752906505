import { ArrowRightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";

import "./DemoQuiz.css";

const AwesomeCard = ({ onClick }) => {
  return (
    <Col span={24} className="resultCardBorder resultCardColor">
      <Row justify="center">
        <span className="fsz24 fw800 col-text-blue mt15">Awesome!</span>
      </Row>
      <Row justify="center" className="fsz15 fwsb col-text-blue">
        you did a great job.
      </Row>
      <Row>
        <Col
          className="w100 cursorpointer"
          style={{
            backgroundColor: "#5A39D6",
            height: 50,
            width: "90%",
            margin: "20px 30px 20px 30px",
            boxShadow: "0px 4px 0px #4224B3",
            borderRadius: "14px",
          }}
          onClick={onClick}
        >
          <p
            className="fsz20 fw600 center"
            style={{
              color: "white",
              fontFamily: "Outfit",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 18,
              letterSpacing: "0.04em",
            }}
          >
            Continue <ArrowRightOutlined />
          </p>
        </Col>
      </Row>
    </Col>
  );
};

export default AwesomeCard;
