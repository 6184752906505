/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { CloseOutlined } from "@ant-design/icons";
import { Col, Progress, Row } from "antd";
import React, { useEffect, useState } from "react";
import AwesomeCard from "./AwesomeCard";
import TryAgainCard from "./TryAgainCard";
import "antd/dist/antd.css";

const PlayScreenGrammar = ({
  questionNumber,
  totalQuestions,
  question,
  answer,
  nextQuestion,
  updateCorrectAnswerCount,
  reset,
  options,
  grade,
}) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [checkFlag, setCheckFlag] = useState(false);

  useEffect(() => {
    if (selectedAnswer === answer) {
      updateCorrectAnswerCount();
    }
  }, [selectedAnswer]);

  const getNextQuestion = () => {
    setSelectedOption(0);
    setSelectedAnswer(null);
    setCheckFlag(false);
    nextQuestion();
  };

  return (
    <div>
      <Col style={{ backgroundColor: "#fff7e5", minHeight: "100vh" }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 12, offset: 6 }}
          xl={{ span: 8, offset: 8 }}
        >
          <Row className="pt18" justify="center">
            <Col span={2}>
              <span>
                {questionNumber}/{totalQuestions}
              </span>
            </Col>
            <Col span={17}>
              <Progress
                percent={(100 * questionNumber) / totalQuestions}
                showInfo={false}
                trailColor="#b7b2a7"
                strokeColor="#3253ce"
                className="center"
              />
            </Col>
            <Col span={3} className="mt5 cursorpointer" onClick={reset}>
              <CloseOutlined className="center" />
            </Col>
          </Row>
          <Col style={{ margin: "22px" }}>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  color: "rgb(86, 31, 231)",
                  fontSize: 22,
                  fontWeight: 600,
                  fontFamily: "Outfit",
                  fontStyle: "normal",
                }}
              >
                {grade}
              </span>
            </Row>
            <Row
              color="#fdc648"
              justify="center"
              style={{ backgroundColor: "#f6f6f6" }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  margin: "14px 0",
                }}
              >
                Choose the correct option
              </span>
            </Row>
            <Row
              style={{
                border: "1px solid #e3e3e3",
                borderTopWidth: "0px",
                backgroundColor: "white",
                justifyContent: "center",
              }}
              className="pt18 pb10"
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  margin: "14px 0",
                  padding: "10px",
                }}
              >
                {question}
              </span>
            </Row>
            {options.map((item, index) => (
              <Row
                key={index}
                style={{
                  border:
                    checkFlag && answer === item && selectedOption !== 0
                      ? "1px solid #00BC67"
                      : checkFlag &&
                        answer !== item &&
                        selectedOption === index + 1
                      ? "1px solid #F04848"
                      : !checkFlag &&
                        selectedAnswer !== null &&
                        selectedOption === index + 1
                      ? "1px solid #5A39D6"
                      : "1px solid #DED8CE",
                  boxShadow:
                    checkFlag && answer === item && selectedOption !== 0
                      ? "0px 4px 0px #00BC67"
                      : checkFlag &&
                        answer !== item &&
                        selectedOption === index + 1
                      ? "0px 4px 0px #F04848"
                      : !checkFlag &&
                        selectedAnswer !== null &&
                        selectedOption === index + 1
                      ? "0px 4px 0px #5A39D6"
                      : "0px 4px 0px #ded8ce",
                  backgroundColor:
                    checkFlag && answer === item && selectedOption !== 0
                      ? "#F0FAF6"
                      : checkFlag &&
                        answer !== item &&
                        selectedOption === index + 1
                      ? "#F9F4EF"
                      : !checkFlag &&
                        selectedAnswer !== null &&
                        selectedOption === index + 1
                      ? "#F2F0FA"
                      : "#f6f6f6",
                  justifyContent: "center",
                  cursor: "pointer",
                  pointerEvents: checkFlag ? "none" : null,
                  borderRadius: 14,
                  margin: "20px 0px 20px 0px",
                  height: "60px",
                }}
                className="pt18 pb10"
                onClick={() => {
                  setSelectedOption(index + 1);
                  setSelectedAnswer(item);
                }}
              >
                <p
                  style={{
                    color:
                      checkFlag && answer === item && selectedOption !== 0
                        ? "#00BC67"
                        : checkFlag &&
                          answer !== item &&
                          selectedOption === index + 1
                        ? "#F04848"
                        : !checkFlag &&
                          selectedAnswer !== null &&
                          selectedOption === index + 1
                        ? "#5A39D6"
                        : "#261A02",
                    fontFamily: "Outfit",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  {item}
                </p>
                {checkFlag && answer === item && selectedOption !== 0 ? (
                  <div style={{ position: "absolute", right: 20 }}>
                    <img src="/playquiz/correct.svg" alt="correct" />
                  </div>
                ) : checkFlag &&
                  answer !== item &&
                  selectedOption === index + 1 ? (
                  <div style={{ position: "absolute", right: 20 }}>
                    <img src="/playquiz/wrong.svg" alt="wrong" />
                  </div>
                ) : null}
              </Row>
            ))}
          </Col>
          <Row
            align="bottom"
            style={{
              marginTop: "24px",
            }}
            className="ml16 mr16"
          >
            {!checkFlag && selectedAnswer != null ? (
              <Row
                style={{
                  width: "100%",
                }}
              >
                <button
                  type="button"
                  onClick={() => setCheckFlag(true)}
                  style={{
                    backgroundColor: "#5A39D6",
                    height: 50,
                    width: "90%",
                    margin: "20px 30px 20px 30px",
                    boxShadow: "0px 4px 0px #4224B3",
                    borderRadius: "14px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontFamily: "Outfit",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 18,
                      letterSpacing: "0.04em",
                      display: "contents",
                    }}
                  >
                    Check&nbsp;&nbsp;
                  </p>
                  <img src="/playquiz/arrow.svg" alt="arrow" />
                </button>
              </Row>
            ) : checkFlag &&
              selectedAnswer === answer &&
              selectedAnswer !== null ? (
              <AwesomeCard onClick={getNextQuestion} />
            ) : checkFlag &&
              selectedAnswer !== answer &&
              selectedAnswer !== null ? (
              <TryAgainCard onClick={getNextQuestion} />
            ) : null}
          </Row>
        </Col>
      </Col>
    </div>
  );
};

export default PlayScreenGrammar;
