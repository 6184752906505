/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Button, Upload, message, Form } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { imageUpload as imageUploadUrl } from "../../utils/apiDict";

const RenderImageUpload = ({ inputId, onUpload }) => {
  const [loading, setLoading] = useState(false);
  const uploadProps = {
    name: "file",
    action: `${process.env.REACT_APP_API_SERVER}${imageUploadUrl}`,
    withCredentials: true,
    accept: "image/jpg, image/jpeg, image/png",
    onChange: (info) => {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        onUpload({
          inputId,
          optionId: inputId,
          data: {
            type: "img",
            value: info.file.response.data.fileName,
          },
        });
        setLoading(false);
      } else if (info.file.status === "error") {
        setLoading(false);
        message.error(`${info.file.name} file upload failed.`);
      } else {
        setLoading(true);
      }
    },
  };

  return (
    <Form.Item>
      <Upload
        name={uploadProps.name}
        action={uploadProps.action}
        withCredentials={uploadProps.withCredentials}
        accept={uploadProps.accept}
        onChange={uploadProps.onChange}
        showUploadList={false}
      >
        <Button loading={loading} title="Add image" icon={<UploadOutlined />} />
      </Upload>
    </Form.Item>
  );
};

export default RenderImageUpload;
